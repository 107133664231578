import { useMultipleContractSingleData } from "state/multicall/hooks"
import { Interface } from '@ethersproject/abi'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import {useMemo} from 'react'

export default ()=>{
    const PAIR_INTERFACE = new Interface(IUniswapV2PairABI)
    const hctUsdtPairAddress = '0xe233ae5F098e987fc90ae18d4eE36c7D2528eFD6'
    const results = useMultipleContractSingleData([hctUsdtPairAddress], PAIR_INTERFACE, 'getReserves')


    return useMemo(() => {
        return results.map((result, i) => {
            const { result: reserves, loading } = result
            if(reserves){
                const { reserve0, reserve1 } = reserves
                return reserve0.toString() / reserve1.toString()
            }
            return null
        })
    }, [results])
}
