// the Uniswap Default token list lives here
// export const DEFAULT_TOKEN_LIST_URL = 'tokens.uniswap.eth'
// export const DEFAULT_TOKEN_LIST_URL = 'tokens.uniswap.eth'

import bscJSON from './token/bsc.json'
import hecoJSON from './token/heco.json'
import ercJSON from './token/erc.json'
import avaxJSON from './token/avax.json'
import avaxStationJSON from './token/avax_station.json'

export const DEFAULT_BSC_TOKEN_LIST_URL = 'bsc'
export const DEFAULT_HECO_TOKEN_LIST_URL = 'heco'
export const DEFAULT_ERC_TOKEN_LIST_URL = 'erc'
export const DEFAULT_AVAX_TOKEN_LIST_URL = 'avax'
export const DEFAULT_AVAX_STATION_TOKEN_LIST_URL = 'avax_station'

export const DEFAULT_LIST_OF_LISTS: string[] = [DEFAULT_AVAX_STATION_TOKEN_LIST_URL, DEFAULT_AVAX_TOKEN_LIST_URL, DEFAULT_BSC_TOKEN_LIST_URL, DEFAULT_HECO_TOKEN_LIST_URL, DEFAULT_ERC_TOKEN_LIST_URL]

export const DEFAULT_TOKEN_LIST_URLS = {
  heco: hecoJSON,
  erc: ercJSON,
  bsc: bscJSON,
  avax: avaxJSON,
  avax_station: avaxStationJSON
}
