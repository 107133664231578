import React from 'react'
import {Flex} from '@hurricaneswap/uikit'
import styled from 'styled-components'
import useMobile from '../../hooks/useMobile'
import StationDes from '../StationDes'
import StationTradingPairs from '../StationTradingPairs'



const Divider = styled.div`
  padding-top: 40px;
  width: 100%
`

const StationTokenInfo = () => {
  const ifMobile = useMobile()


  return (
    <Flex flexDirection='column' justifyContent='center' alignItems='center'>
      <StationDes/>

      <Divider/>

      {/* <StationTradingPairs/> */}
    </Flex>
  )
}

export default StationTokenInfo
