import { ChainId, Currency, CurrencyAmount, currencyEquals, ETHER, Token } from '@glhf-libs/sdk'
import React, { CSSProperties, MutableRefObject, useCallback, useMemo } from 'react'
import { FixedSizeList } from 'react-window'
import styled from 'styled-components'
import { Text, Flex } from '@hurricaneswap/uikit'
import { useActiveWeb3React } from '../../hooks'
import { useSelectedTokenList, WrappedTokenInfo } from '../../state/lists/hooks'
import { LinkStyledButton, TYPE } from '../Shared'
import CurrencyLogo from '../CurrencyLogo'
import { MouseoverTooltip } from '../Tooltip'
import { FadedSpan, MenuItem } from './styleds'
import { useBaseCoin } from '../../hooks/useBaseCoin'

const { main: Main } = TYPE

function currencyKey(currency: Currency): string {
  return currency instanceof Token ? currency.address : currency === ETHER ? 'ETHER' : ''
}

const StyledBalanceText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  max-width: 5rem;
  text-overflow: ellipsis;
`

const Tag = styled.div`
  background-color: ${({ theme }) => theme.colors.tertiary};
  color: ${({ theme }) => theme.colors.textSubtle};
  font-size: 14px;
  border-radius: 4px;
  padding: 0.25rem 0.3rem 0.25rem 0.3rem;
  max-width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-self: flex-end;
  margin-right: 4px;
`
const Wrapper = styled.div`
  //padding: 8px 20px;

`

function Balance({ balance }: { balance: CurrencyAmount }) {
  return <StyledBalanceText title={balance.toExact()}>{balance.toSignificant(4)}</StyledBalanceText>
}

const TagContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

function TokenTags({ currency }: { currency: Currency }) {
  if (!(currency instanceof WrappedTokenInfo)) {
    return <span />
  }

  const { tags } = currency
  if (!tags || tags.length === 0) return <span />

  const tag = tags[0]

  return (
    <TagContainer>
      <MouseoverTooltip text={tag.description}>
        <Tag key={tag.id}>{tag.name}</Tag>
      </MouseoverTooltip>
      {tags.length > 1 ? (
        <MouseoverTooltip
          text={tags
            .slice(1)
            .map(({ name, description }) => `${name}: ${description}`)
            .join('; \n')}
        >
          <Tag>...</Tag>
        </MouseoverTooltip>
      ) : null}
    </TagContainer>
  )
}

function CurrencyRow({
                       currency,
                       currencyB,
                       onSelect,
                       isSelected,
                       otherSelected,
                       style,
                       type
                     }: {
  currency: Currency
  currencyB: Currency
  onSelect: () => void
  isSelected: boolean
  otherSelected: boolean
  style: CSSProperties
  type: any
}) {
  const { account, chainId } = useActiveWeb3React()
  const key = currencyKey(currency)
  const selectedTokenList = useSelectedTokenList()

  const baseCoin = useBaseCoin(currency, chainId)
  const currencyBCoin = useBaseCoin(currencyB, chainId)
  // only show add or remove buttons if not on selected list

  return (
    <Wrapper
      style={style}
      className={`token-item-${key}`}>
      <MenuItem
        onClick={() => (isSelected ? null : onSelect())}
        disabled={isSelected}
        selected={otherSelected}
      >
        <Flex flexDirection='row' alignItems='center'>
          <CurrencyLogo  style={{position: 'relative', zIndex: 1}} currency={currency} size="24px" />
          <CurrencyLogo style={{transform: 'translate3d(-10px, 0, 0)', position: 'relative', zIndex: 0}} currency={currencyB} size="24px" />
        </Flex>
        <RowWrapper>
          <Text title={currency.name} fontSize={type === 'user' ? '12px' : '16px'}>{ baseCoin } / { currencyBCoin }</Text>
        </RowWrapper>
        <TokenTags currency={currency} />

      </MenuItem>
    </Wrapper>
  )
}

export default function PairList({
                                       height,
                                       currencies,
                                       selectedCurrency,
                                       onCurrencySelect,
                                       otherCurrency,
                                       fixedListRef,
                                       showETH,
                                       itemSize=90,
                                       padding,
                                       type
                                     }: {
  height: number
  currencies: Currency[]
  selectedCurrency?: Currency | null
  onCurrencySelect: (currencyA: Currency, currencyB: Currency) => void
  otherCurrency?: Currency | null
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
  showETH: boolean
  itemSize?: number
  padding?: string
  type?: any
}) {
  const { chainId } = useActiveWeb3React()

  const itemData = useMemo(() => {
    return  (showETH ? [Currency.ETHER, ...currencies] : [...currencies]).filter(i=>i?.symbol ? (i?.symbol.toUpperCase() !== 'USDT' && i?.symbol.toUpperCase() !== 'AUSDT') : false)

  }, [currencies, showETH])

  const pairB = useMemo(()=>{
    const u = currencies.filter(i=>i?.symbol ? (i?.symbol.toUpperCase() === 'USDT' || i?.symbol.toUpperCase() === 'AUSDT') : false)
    if(u && u.length>0){
      return u[0]
    }
    return Currency.ETHER
  }, [currencies])

  const Row = useCallback(
    ({ data, index, style }) => {
      const currency: Currency = data?.itemData[index]
      const currencyB: Currency = data?.pairB
      const isSelected = Boolean(selectedCurrency && currencyEquals(selectedCurrency, currency))
      const otherSelected = Boolean(otherCurrency && currencyEquals(otherCurrency, currency))
      const handleSelect = () => onCurrencySelect(currency, currencyB)
      return (
        <CurrencyRow
          style={{...style, padding, borderRadius: '8px'}}
          currency={currency}
          currencyB={currencyB}
          isSelected={isSelected}
          onSelect={handleSelect}
          otherSelected={otherSelected}
          type={type}
        />
      )
    },
    [type, padding, onCurrencySelect, otherCurrency, selectedCurrency]
  )

  const itemKey = useCallback((index: number, data: any) => currencyKey(data[index]), [])
  return (
    <FixedSizeList
      height={height}
      ref={fixedListRef as any}
      width="100%"
      itemData={{itemData, pairB}}
      itemCount={itemData.length}
      itemSize={itemSize}
      itemKey={itemKey}
    >
      {Row}
    </FixedSizeList>
  )
}
