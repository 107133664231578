// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react'
import styled, { css } from 'styled-components'
import _ from 'lodash'
import {
  Button,
  Text,
  Flex,
  HelpLinkCard,
  MessageLinkCard,
  HomeArrowIcon,
  OptionCard,
  Skeleton,
  TwitterIcon,
  TelegramIcon,
  MediumIcon
} from '@hurricaneswap/uikit'
import { Link, useHistory } from 'react-router-dom'
import CoinToShow from './config'
import { helps, messages } from './config'
import HomePageFooter from '../../components/HomePageFooter'
import useTheme from '../../hooks/useTheme'
import { useTranslation } from 'react-i18next'
import { SafeIcon, FastIcon, SimpleIcon } from './Icons'
import i18next from '../../i18n'
import useMobile from '../../hooks/useMobile'
import useHctUsdtBalance from 'hooks/useHctUsdtBalance'
import PeckshieldIcon from '../../assets/imgs/peckShield.svg'

const Wrapper = styled.div<{ ifMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width:100%;
  font-size: ${ ({ ifMobile }) => (ifMobile ? '14px' : '16px') };
`
const FlexSection = styled (Wrapper)`
  font-size: ${ ({ ifMobile }) => (ifMobile ? '14px' : '16px') };
`

const Title = styled (Text)`
  font-family: GTTextBold;
  font-size: 2.5em;
  line-height: 46px;
  text-align: center;
  margin-bottom: 20px;
  color: ${ ({ theme }) => theme.colors.text };
`

const IconContainer = styled.div`
  width: 47.83px;
  height: 47.83px;
  background: #FFFFFF;
  border-radius: 50%;
  display: flex;
  align-items:center;
  justify-content: center;
`
const CardWrapper = styled.div`
  >div{
    width: 100%;
    max-width: 490px;
    margin-top: 10px;
  }
  margin: auto;
  width: 100%;
  max-width: 1000px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 490px);
  grid-gap: 20px;
  @media (max-width: 522px) {
      grid-template-columns: repeat(auto-fill, 100%);
  
  }
`

const GridContainer = styled.div<{ ifMobile: boolean }>`
  display: grid;
  justify-content: center;
  grid-template-columns: ${ (ifMobile => (ifMobile ? '1fr 1fr 1fr' : 'repeat(auto-fill, 300px)')) };
  grid-gap: 20px;
`

const CoinCardWrapper = styled.div<{ ifMobile: boolean }>`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(auto, 230px));
  grid-gap: 35px 20px;
  width: 100%;
  max-width: 100%;
  margin-bottom: ${ ({ ifMobile }) => (ifMobile ? '30px' : '136px') };
  
  > div{
    width: 100%;
    max-width: 230px; 
  
  }
  
  @media (max-width: 510px) {
      grid-template-columns: repeat(2, 1fr);
      >div{
        width: 100%;
        height: 210px;
        img{
            transform: scale(0.7);
            transform-origin: bottom right;
        }
      }
      
  }
  
  @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
      >div{
        width: 100%;
      }
  }
`

const GridWrapper = styled (Flex)<{ ifMobile: boolean }>`
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(auto, 320px));
  grid-gap: ${ ({ ifMobile }) => (ifMobile ? '15px' : '20px') };;
 `

const DataCard = styled.div<{ ifMobile: boolean }>`
  position:relative;
  background: ${ ({ theme }) => theme.isDark ? 'rgba(255, 255, 255, 0.08)' : 'rgba(255, 255, 255, 0.5)' };
  border: 1px solid ${ ({ theme }) => theme.isDark ? 'rgba(77, 60, 85, 0.12)' : '#F1EDFF' }; 
  backdrop-filter: blur(50px);
  border-radius: 24px;
  // max-width: 320px;
  width: 100%;
  height: 184px;
  padding:32px;
  & span{
    color:inherit;
    font-family: GT Eesti Pro Text;
    font-style: normal;
    font-weight: bold;
    font-size: 2em;
    line-height: 37px;
    margin-right:8px
  }
  & div{
    margin-bottom:16px
  }
  div:first-child
  { 
    font-weight: normal;
    font-size: 1.25em;
    line-height: 22px;
  }
  div:last-child
  { 

    font-weight: normal;
    font-size: 1.25em;
    line-height: 28px;
    color: ${ ({ theme }) => theme.isDark ? 'rgba(255, 255, 255, 0.44)' : 'rgba(77, 60, 85, 0.65)' };

  }
  #px {
    font-size: 1em; 
  }
  
  ${ ({ ifMobile }) => (ifMobile && css`grid-area: 2 / 1 / 2 / 3;`) }
  
  
`
const WrapOptionCard = styled (OptionCard)`
  &.dynamic:hover {
    transform: scale(0.98);
    img{
        transform: scale(1.05)
    }
}
`

const Info = styled.div`
margin-top: 10px;
margin-bottom: 10px;
text-align: center;
//--progress:0.5;
width:100%;
max-width: 300px;
display:flex;
flex-direction:column;
align-items:center;
margin-top:40px;
transition:all 0.5s ;
:hover{
  transform:scale(1.2)
 // animation-duration: 5s;
 // animation-name: swashIn;
  //animation-iteration-count: infinite
}

@keyframes swashIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  90% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
/*@keyframes swashIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(-90deg);
    transform: perspective(800px) rotateX(-90deg);
  }
  50% {
    opacity: 1;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(50deg);
    transform: perspective(800px) rotateX(50deg);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(0deg);
    transform: perspective(800px) rotateX(0deg);
  }
}*/
   

/*transition: 0.4s transform ease-in-out; 
 transform:scale(calc(1.8 - (0.8 * var(--progress)))) translateY(calc(60px * (1 - var(--progress))));
 opacity:var(--progress); */

:last-of-type{
margin-bottom:80px;
}

`
const Ps = styled.div<{ ifMobile: boolean }>`
  :hover{
    section{
        // background: rgba(141, 128, 213, 0.1);
    }
    .audit span{
        border-bottom: 1px solid #5723A6;
    }
    
  }
  
  section{
    transition: all linear 0.2s;
  }
  cursor: pointer;
  width:100vw;
  //height:248px;
  
  display: flex;
  align-items: center;
  
  // background:${ ({ theme }) => theme.isDark ? 'rgba(77, 60, 85, 0.25)' : 'rgba(241, 237, 255, 0.4)' };
  section:first-child{
    width:1000px;
    margin:0 auto;
    position:relative
  }
  margin-top:  ${ ({ ifMobile }) => (ifMobile ? '48px' : '114px') };
  margin-bottom:  ${ ({ ifMobile }) => (ifMobile ? '32px' : '64px') };

`
const Socialflex = styled (Flex)`
 width:100vw;
 justify-content:left;
 margin-top:60px;
 svg {
   margin-left:20px
 }
`
const Text1 = styled (Text)<{ ifMobile: Boolean }>`
  font-size: 0.875em;
  line-height: 16px;
  letter-spacing: 0.5px;
  width: 100%;
  max-width:588px;
  min-width: ${ ({ ifMobile }) => (ifMobile ? '100%' : '519px') };
  text-align: ${ ({ ifMobile }) => (ifMobile ? 'center' : 'left') };
  flex: 1;
  margin-right:80px;
  margin-bottom: 20px;
  color:${ ({ theme }) => theme.isDark ? 'rgba(255, 255, 255, 0.65)' : 'rgba(77, 60, 85, 0.85)' };
  
  @media (max-width: 1000px) {
    margin-right:0; 
  }
`

const Privacy = styled.div`
margin-top: 24px;
font-family: GTText;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
color: #C7C8D9;
margin-left:20px
`

const PsImg = styled.img<{ width: String }>`
  width: ${ ({ width }) => (width) };
`
const LinkWithHover = styled.a`
  width: 100%;
  
  .audit span{
    transition: all linear 0.2s;
  }
`

const InvestLinkWithHover = styled.a`
    transition: all linear 0.2s;
    filter: ${ ({ theme }) => (theme.isDark ? 'contrast(0.6) opacity(0.8)' : ' opacity(0.6) ') };
  // .rectFill{
    // fill: #8D80D5;
    // color: #8D80D5;
    // transition: all linear 0.2s;
  // }
  :hover{
      filter: ${ ({ theme }) => (theme.isDark ? 'contrast(0.3) opacity(1)' : ' opacity(1) ') };
    // .rectFill{
      // fill: #6425C5;
      // color: #6425C5;
    // }
  }
`

const Triangle = styled.div<{ color: String; up:Boolean }>`
  width: 0;
  height: 0;
  ${({up}) => up ? 'border-bottom: 10px solid #4BDD97' : 'border-top: 10px solid #FC5A94'};
  //border-top: 10px solid ${ ({ color }) => (color) };
  //border-bottom: 10px solid ${ ({ color }) => (color) };
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  margin-left:6px
`

const initialValue = {
  hct: {
    price: '',
    arp: ''
  },
  cake: {
    price: '',
    arp: ''
  },
  eth: {
    price: '',
    arp: ''
  },
  uni: {
    price: '',
    arp: ''
  },
  eps: {
    price: '',
    arp: ''
  },
  link: {
    price: '',
    arp: ''
  },
  mdx: {
    price: '',
    arp: ''
  },
  avax: {
    price: '',
    arp: ''
  }
}

const imgs = [
  {
    src: 'at',
    href: 'https://capitalant.com/',
    key: 'at'
  }, {
    src: 'seven',
    href: 'https://www.7oclockcapital.com/',
    key: 'seven'
  }, {
    src: 'limestone',
    href: 'https://www.theblockbeats.com/',
    key: 'limestone'
  }, {
    src: 'mexc',
    href: 'https://www.mexc.com/',
    key: 'mexc'
  }, {
    src: 'kernel',
    href: 'https://www.kernel-ventures.com/portfolio',
    key: 'kernel'
  }, {
    src: 'avatar',
    href: 'https://www.avaxavatar.com/',
    key: 'avatar'
  }, {
    src: 'kine',
    href: 'https://kine.io/',
    key: 'kine'
  }, {
    src: 'duckDao',
    href: 'https://duckdao.io/',
    key: 'duck'
  }, {
    src: 'daowell',
    href: null,
    key: 'daowell'
  }, {
    src: 'jrr',
    href: 'https://jrr.group/',
    key: 'jrr'
  }, {
    src: 'ld',
    href: 'https://ldcap.com/',
    key: 'ld'
  }, {
    src: 'kuCoin',
    href: 'https://www.kucoin.com/',
    key: 'kuCoin'
  }, {
    src: 'spark',
    href: 'https://sparkdigitalcapital.com/',
    key: 'spark'
  }, {
    src: 'avalaunch',
    href: 'https://avalaunch.app/',
    key: 'avalaunch'
  }, {
    src: 'Redline',
    href: 'http://www.honglianziben.com',
    key: 'redline'
  }, {
    src: 'Gate',
    href: 'https://www.gate.io/',
    key: 'gate'
  }
]

const Home = () => {
  const theme = useTheme ()
  const backgroundcolor = theme.isDark ? 'linear-gradient(90deg, #8D80D5 0%, #A37CDD 91.07%)' : 'linear-gradient(90deg, #5723A6 0%, #8D80D5 91.07%)'
  const CoinID = { 1027: 'eth', 7186: 'cake', 7083: 'uni', 8983: 'eps', 4512: 'link', 8335: 'mdx', 5805: 'avax' }
  const { t } = useTranslation ()
  const ifMobile = useMobile ()
  const ifMobileForPeckShield = useMobile (1000)
  const { push } = useHistory ()
  const [coinData, setCoinData] = useState (window.localStorage.getItem ('cards') && JSON.parse (window.localStorage.getItem ('cards')).hct ? JSON.parse (window.localStorage.getItem ('cards')) : initialValue)
  const [helpTrans, setHelpTrans] = useState (helps)
  const [messageTrans, setMessageTrans] = useState (messages)

  const [hctBalance] = useHctUsdtBalance ()

  useMemo (() => {
    if (hctBalance) {
      setCoinData (old => {
        const newData = JSON.parse (JSON.stringify (old))
        newData.hct.arp = (100 - (5 / (+hctBalance))).toFixed (2)
        newData.hct.price = +hctBalance.toFixed (3)
        window.localStorage.setItem ('cards', JSON.stringify (newData))
        return newData
      })
    }
  }, [hctBalance])

  useEffect (() => {
    const ws = new WebSocket ('wss://stream.coinmarketcap.com/price/latest')
    ws.onopen = (event) => {
      ws.send (JSON.stringify ({
        method: 'subscribe',
        id: 'price',
        data: { cryptoIds: [1027, 7186, 7083, 8983, 4512, 8335, 5805], index: 'detail' }
      }))
    }
    ws.onmessage = (msg) => {
      const msg1 = JSON.parse (msg.data)
      if (msg1 !== undefined) {
        const name = CoinID[msg1.d.cr.id]
        const price = msg1.d.cr.p
        const arp = msg1.d.cr.p24h
        if (name && price && arp && coinData[name]) {
          coinData[name]['price'] = (+price).toFixed (3)
          coinData[name]['arp'] = (+arp).toFixed (2)
          const data = _.cloneDeep (coinData)
          window.localStorage.setItem ('cards', JSON.stringify (data))
          setCoinData (data)
        }
      }
    }

    return () => {
      ws.close ()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect (() => {
    const helpTrans = helps.map ((help) => ({ label: t (help['label']), href: help['href'] }))
    const messageTrans = messages.map ((message) => ({ label: t (message['label']), href: message['href'] }))
    const data = _.cloneDeep (helpTrans)
    const data1 = _.cloneDeep (messageTrans)
    setHelpTrans (data)
    setMessageTrans (data1)
  }, [i18next.languages])

  const routerToSwap = (coin) => {

    if (coin.title == 'avax') {
      push (`/swap`)
    } else {
      push (`/swap?inputCurrency=${ coin.address }`)
    }
  }

  return (
    <Wrapper id='wrapper' ifMobile={ ifMobile }>
      <FlexSection ifMobile={ ifMobile }>
        <Title>HurricaneSwap</Title>
        <Text textAlign='center' mb={ 20 } fontSize='1.5em' fontWeight='400' maxWidth='642px' width='100vw'>
          { t ('homePageSlogan') }
        </Text>
        <Button as={ Link } to='/swap' style={ {
          justifyContent: 'space-between',
          fontSize: '1.75em',
          height: '80px',
          width: '280px',
          boxShadow: '0px 14px 60px rgba(139, 70, 182, 0.4)',
          borderRadius: '68px',
          background: backgroundcolor,
          marginBottom: ifMobile ? '50px' : '122px',
          textAlign: 'center'
        } }>{ t ('StartTrading') }
          <IconContainer><HomeArrowIcon width='40px'/></IconContainer></Button>
      </FlexSection>
      <section style={ { width: '100%', maxWidth: '1000px' } }>

        <Text textAlign='left' fontSize='1.5em' mb={ 30 }>{ t ('Ethereum, Binance, Huobi, Avalanche tokens') }</Text>
        <CoinCardWrapper ifMobile={ ifMobile }>
          { CoinToShow.map ((coin) => (
            <WrapOptionCard onClick={ () => {
              routerToSwap (coin)
            } } key={ coin.currency } chain={ coin.chain }
                            currency={ coin.currency } cardColor={ coin.color } className={ ifMobile ? '' : 'dynamic' }>
              <Text fontSize='1.25em!important'>{ coin.title.toUpperCase () }</Text>
              { (coinData[coin.title] && coinData[coin.title].price)
                ? (<Text fontSize='1.5em!important'>${ coinData[coin.title].price }</Text>)
                : (<Skeleton animation="pulse" variant="rect" width="60%" height="32px"/>) }
              { ( coinData[coin.title] && coinData[coin.title].arp)
                ? (
                  <Flex alignItems='center'>
                    <Text fontSize='1em!important'>{ coinData[coin.title].arp } % </Text>
                    <Triangle up={coinData[coin.title].arp > 0 ? true :false}/>
                  </Flex>
                  )
                : (<Skeleton animation="pulse" variant="rect" width="30%" height="22px"/>) }

            </WrapOptionCard>
          )) }
        </CoinCardWrapper>
      </section>

      <section style={ { width: '100%', maxWidth: '1000px' } }>
        <Title style={ { textAlign: ifMobile ? 'center' : 'left' } }>{ t ('Why HurricaneSwap') }</Title>
        <GridContainer id='msg' ifMobile={ ifMobile }>
          <Info className={ ifMobile ? 'info swashIn' : 'info' }>
            <SimpleIcon/>
            <Text mt={ 1 } fontSize='20px' fontWeight='400'>{ t ('Easier') }</Text>
            <Text>{ t ('Add liquidity in 2 steps, 15 seconds') }</Text>
          </Info>
          <Info className={ ifMobile ? 'info swashIn' : 'info' }>
            <SafeIcon/>
            <Text mt={ 1 } fontSize='20px' fontWeight='400'>{ t ('Safer') }</Text>
            <Text>{ t ('Avoid the risk of money laundering') }</Text>
          </Info>
          <Info className={ ifMobile ? 'info swashIn' : 'info' }>
            <FastIcon/>
            <Text mt={ 1 } fontSize='20px' fontWeight='400'>{ t ('Cheaper') }</Text>
            <Text>{ t ('0.2% cross-chain fee, lower slippage') }</Text>
          </Info>
        </GridContainer>


        { !ifMobile && (
          <>
            <Title style={ { textAlign: ifMobile ? 'center' : 'left' } }>{ t ('Need help with something?') }</Title>
            <CardWrapper>
              <HelpLinkCard title={ t ('Help') } helps={ helpTrans } style={ { maxWidth: '490px', width: '100%' } }
                            isDark={ theme.isDark }/>
              <MessageLinkCard title={ t ('Announcement') } helps={ messageTrans }
                               style={ { maxWidth: '490px', width: '100%' } } isDark={ theme.isDark }/>
            </CardWrapper>
          </>
        )
        }
      </section>

      <Ps ifMobile={ ifMobile }>
        <LinkWithHover
          target='_blank'
          href="https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-HurricaneSwap-v1.0.pdf">
        <section style={{width: '100%', display: 'flex', alignItems: 'center'}}>
          <div
            style={ {
              margin: 'auto',
              maxWidth: '1000px',
              width: '100%',
              paddingLeft: ifMobileForPeckShield ? '16px' : '0',
              paddingRight: ifMobileForPeckShield ? '16px' : '0'
            } }>
            { ifMobileForPeckShield
              ? (
                <Flex flexDirection='column' justifyContent='space-between' alignItems='center'>
                  <Flex mb='16px' flexDirection='row' justifyContent='space-between' alignItems='center'>

                      <Flex flexDirection='column'>
                        <PsImg width={ ifMobileForPeckShield ? '218px' : '343px' } src={ PeckshieldIcon }/>
                        <Text className='audit' color='#5723A6' width='100%'
                              textAlign={ ifMobileForPeckShield ? 'center' : 'right' }>
                          <span>{ t ('See Auditing Report') }</span> </Text>
                      </Flex>
                  </Flex>
                  <Text1 ifMobile={ ifMobileForPeckShield }>{ t ('Packshield introduce') }</Text1>
                </Flex>
              )
              : (
                <>
                  <Flex alignItems='center' mt='20px' mb='20px' justifyContent='space-between' flexWrap='wrap'>
                    <Text1 ifMobile={ ifMobileForPeckShield }>{ t ('Packshield introduce') }</Text1>
                    <Flex mb='20px' alignItems='center'>
                        <Flex flexDirection='column'>
                          <PsImg width={ ifMobileForPeckShield ? '218px' : '343px' } src={ PeckshieldIcon }/>
                          <Text className='audit' color='#5723A6' width='100%'
                                textAlign={ ifMobileForPeckShield ? 'center' : 'right' }>
                            <span>{ t ('See Auditing Report') }</span> </Text>
                        </Flex>
                    </Flex>
                  </Flex>
                </>
              )
            }
          </div>


        </section>
        </LinkWithHover>
      </Ps>

      <Flex mb='30px' alignItems='center' flexDirection='column'>
        <Text maxWidth='1000px' width='100%' fontWeight='600' mb='21px' fontSize='32px'>{ t ('Investors') }</Text>
        <Flex maxWidth='1140px' alignItems='center' flexWrap='wrap'>
          {
            imgs.map (i => {
              return <Flex key={ i?.key } justifyContent='center' minWidth='138px' p='20px'
                           maxWidth={ ifMobile ? '50%' : '25%' } width='100%' alignItems='center'>
                <InvestLinkWithHover href={ i?.href } target='_blank'>
                  <img src={ `/images/funding/${ i?.src }.svg` } alt={ i?.href }/>
                </InvestLinkWithHover>
              </Flex>
            })
          }
        </Flex>
      </Flex>


      { ifMobile && (
        <Flex flexDirection='column'>
          <Socialflex>
            <a href="https://twitter.com/hurricaneswap"><TwitterIcon/></a>
            <a href="https://t.me/HurricaneSwap"><TelegramIcon/></a>
            <a href="https://hurricaneswap.medium.com"><MediumIcon/></a>
          </Socialflex>
          <Privacy>
            © 2021 HurricaneSwap
          </Privacy>
        </Flex>

      ) }


      {

        !ifMobile && (<HomePageFooter/>)
      }
    </Wrapper>
  )
}

export default Home
