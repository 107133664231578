import { useInitContract } from './Station'
import { useActiveWeb3React } from './index'
import { useAsyncByAwait } from './useAyncByAwait'

export default ()=>{
  const {chainId, account} = useActiveWeb3React()
  const {error: contractError, contract} = useInitContract()

  const ifAllowSwap = async ({token0Address, token1Address})=>(contract?.isSwapEnable(token0Address, token1Address))

  return useAsyncByAwait(ifAllowSwap)

}
