import React, { useMemo } from 'react'
import styled from 'styled-components'
import { NavLink, useLocation } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from '@hurricaneswap/uikit'
import TranslatedText from '../TranslatedText'

const StyledNav = styled.div`
  z-index: 5;
  // height: 100%;
  display: flex;
  align-items: center;
`

const StyledHeader = styled.div`
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    padding: 1.5rem 2.5rem;
`

const StyledNavLink = styled(NavLink)`

color: ${({theme})=>(theme.colors.text)}
`

const Nav = ({ activeIndex = 0 }: { activeIndex?: number }) => {
  const { search, pathname } = useLocation()

  const Links = useMemo(()=>{
    if(pathname.includes('station')){
      return (
        <StyledNavLink activeStyle={{opacity: 1}} style={{marginRight: '23px', opacity: 0.45}} to={{pathname: "/stationPool", search}}>Station</StyledNavLink>
      )
    }
    return (
      <>
        <StyledNavLink activeStyle={{opacity: 1}} style={{marginRight: '23px', opacity: 0.45}} to={{pathname: "/swap", search}}>Swap</StyledNavLink>
        <StyledNavLink activeStyle={{opacity: 1}} style={{marginRight: '23px', opacity: 0.45}} to={{pathname: "/pool", search}}>Liquidity</StyledNavLink>
      </>
    )
  }, [pathname, search])

  return (
    (
      <StyledNav>
        {Links}
      </StyledNav>
    )
  )
}

export default Nav
