import React, { createContext, useRef, useEffect, useState, useCallback, useMemo } from 'react'
import { Contract } from '@ethersproject/contracts'
import { useLocation } from 'react-router-dom'
import { Currency, FACTORY_ADDRESS_HECO, Token, FACTORY_ADDRESS_AVAX, FACTORY_ADDRESS_BSC,FACTORY_ADDRESS_AVAX_TESTNET, FACTORY_ADDRESS_HECO_TESTNET  } from '@glhf-libs/sdk'
import { useCountDown, useRequest } from 'ahooks'
import { useContract } from './useContract'
import AVAX_STATION_ABI from '../constants/abis/avaxStation.json'
import { useStationActionHandlers, useStationState } from '../state/station/hooks'
import { useActiveWeb3React } from './index'
import { LTC, USDT } from '../constants'
import useAuth from './useAuth'
import { setupNetwork } from '../utils'

/**
 * 1. 10:30 swap 关闭，开始同步station(轮询合约直至完成)
 * 2. 同步完成后，开启5分钟 station-addLiquidity 通道，硬性5分钟关闭
 * 3. 再次同步，轮询合约直至完成
 * 4. swap 启动
 * */

export const useIfOnStationPage = () => {
  const { pathname } = useLocation ()

  return useMemo (() => {
    return pathname.includes ('station')
  }, [pathname])
}

export const usePolling = (callback) => {
  const { data, loading, run, cancel, error } = useRequest (callback, {
    pollingInterval: 10000,
    pollingWhenHidden: false
  })
  return { data, loading, run, cancel, error }
}

export const useLastUpdated = () => {
  const { account, chainId } = useActiveWeb3React ()
  const { onUpdateSync, onUpdateAllowLiquidity, onUpdateLastUpdateTime, onUpdateAllowStation, onUpdateAllowSwap } = useStationActionHandlers ()
  const { allowLiquidity, allowStation } = useStationState ()
  const { dispatchIsLiquidEnable, status: stationLiquidEnable } = useIsLiquidEnable ()
  const { dispatchIsSwapEnable, status: stationSwapEnable } = useIsSwapEnable ()

  const tellTime = (contract) => new Promise (res => {
    res (Math.round (+new Date () / 1000))
  })
  const { data, loading, run, cancel, error } = useRequest (tellTime, {
    pollingInterval: 1000,
    // pollingInterval: 999999999,
    manual: true,
    throwOnError: true,
    onSuccess: async (result, params: any) => {
      const [contract] = params
      const _resultData = await contract?.lastUpdated ()
      callbacks (contract, _resultData)
    }
  })

  const callbacks = useCallback ((contract, requestData) => {
    if (requestData?._hex) {
      const tt = 1623775656 || parseInt (requestData._hex, 16)
      const _day = +new Date (tt * 1000).getDate ()

      const t = new Date ()
      const day = t.getDate ()

      dispatchIsLiquidEnable (contract)
      dispatchIsSwapEnable (contract)

      if (_day < day) {
        onUpdateAllowStation (false)
        onUpdateAllowSwap (true)
        onUpdateSync (false)
      } else if (_day === day) {
        if (+t / 1000 - tt < 300 && +t / 1000 - tt > 0 && stationLiquidEnable) {
          console.log ('station启动', stationSwapEnable, allowStation)
          // 5分钟station开启，5分钟后自动关闭station
          onUpdateAllowStation (true)
          onUpdateLastUpdateTime (tt + 300)
          // onUpdateAllowSwap暂时没用，新接口通过查询交易对来判断是否允许swap
          // 详情查看state/swap/hooks SWAP_STATUS
          onUpdateAllowSwap (false)
          onUpdateSync (false)
        } else if (!stationSwapEnable && !allowStation) {
          // 同步状态，显示同步中...
          console.log ('同步中...', stationSwapEnable, allowStation)
          onUpdateSync (true)
        } else {
          console.log ('同步完成', stationSwapEnable, allowStation)
          onUpdateAllowSwap (true)
          onUpdateSync (false)
        }
      }
    }
  }, [onUpdateSync, dispatchIsSwapEnable, dispatchIsLiquidEnable, stationSwapEnable, allowStation, stationLiquidEnable, onUpdateLastUpdateTime, onUpdateAllowStation, onUpdateAllowSwap])
  return { run, cancel }

}

export const useIsLiquidEnable = () => {
  const { account, chainId } = useActiveWeb3React ()
  const [status, setStatus] = useState (false)

  // @ts-ignore
  const dispatchIsLiquidEnable = useCallback ((contract) => {
    const _ = async () => {
      const res = await contract?.isLiquidEnable (LTC.address, USDT.address, account)
      setStatus (res)
    }
    if (contract) {
      _ ()
    }
  }, [account])

  return { dispatchIsLiquidEnable, status }
}

export const useIsSwapEnable = () => {
  // 轮询交易对，来确认当前是否在同步状态
  // 参数需要一个映射过来的币
  const { account, chainId } = useActiveWeb3React ()
  const [status, setStatus] = useState (false)

  // @ts-ignore
  const dispatchIsSwapEnable = useCallback ((contract) => {
    const _ = async () => {
      const res = await contract?.isSwapEnable (LTC.address, USDT.address)
      setStatus (res)
    }
    if (contract) {
      _ ()
    }
  }, [])

  return { dispatchIsSwapEnable, status }
}

export const useCountingDown = (props?: any) => {
  const [countdown, setTargetDate, formattedRes] = useCountDown ({
    ...props
  })
  const { minutes, seconds } = formattedRes

  return {
    minutes, seconds, setTargetDate, countdown
  }
}

export const useSwapStatusContract = (): Contract | null => {
  return useContract (FACTORY_ADDRESS_AVAX, AVAX_STATION_ABI, false)
}

export const useExactTokenStatusContract = (): Contract | null => {
  return useContract (FACTORY_ADDRESS_AVAX, AVAX_STATION_ABI, false)
}

export const useInitContract = () => {
  const { chainId } = useActiveWeb3React ()
  const result = useMemo (() => {
    if (chainId === 43114) {
      console.log('avax')
      return FACTORY_ADDRESS_AVAX
    }
    if (chainId === 43113) {
      console.log('avax_test')
      return FACTORY_ADDRESS_AVAX_TESTNET
    }
    if (chainId === 56) {
      console.log('bsc')
      return FACTORY_ADDRESS_BSC
    }
    if (chainId === 256) {
      console.log('heco_test')
      return FACTORY_ADDRESS_HECO_TESTNET
    }
    return undefined
  }, [chainId])

  const contract = useContract (result, AVAX_STATION_ABI, false)


  return useMemo(()=>{
    if(!result){
      console.error('no contract matched')
      return {error: 'No Contract Matched', contract: null}
    }
    return {error: undefined, contract}

  }, [result, contract])
}

export const useCallContract = (contract) => {
  const _contract = useRef (contract?.isSwapEnable)
  const [status, setStatus] = useState (undefined)


  useEffect (() => {
    if (contract && contract.restrictedTokens) {
      _contract.current = contract.isSwapEnable
    }
  }, [contract])

  const callExactTokenStatus = useCallback (async (token0, token1) => {
    setStatus (undefined)
    try {
      const result = await _contract.current (token0, token1)
      setStatus (result)
    } catch (error) {
      setStatus (undefined)
    }
  }, [])


  return { status, callExactTokenStatus }
}

export const useSwitchChain = () => {
  const { login } = useAuth ()
  const { account } = useActiveWeb3React ()

  return useCallback (async (_chainId) => {
    let res
    if (account) {
      res = await setupNetwork (_chainId)
    } else if (!account) {
      res = await login (undefined, _chainId)
    }
    return res
  }, [login, account])
}
