import React from 'react'
import styled from 'styled-components'
import { Card } from '@hurricaneswap/uikit'
import { Link as HistoryLink, useLocation } from 'react-router-dom'
import { ArrowLeft } from 'react-feather'


export const FixedPosition = styled.div`
  top: 0;
  position: relative;
  z-index: 5;
  max-width: 464px;
  width: 100%;
`
export const BodyWrapper = styled (Card)`
  // background: rgba(255, 255, 255, 0.6);
  background: ${({theme})=>(theme.colors.swapContainer)};
  max-width: 464px;
  width: 100%;
`

export const BodyGradiantWrapper = styled.div`
  border-radius:20px;
  max-width: 464px;
  z-index: 0.5;
  background:transparent;
  // box-shadow:${ ({ theme }) => (!theme.isDark ? '-10px 0px 100px #f9ebf1 ,0px -10px 100px #f9ebf1,10px 0px 100px #f9ebf1,0px 10px 100px #f9ebf1' : 'none') }; 
  border:${ ({ theme }) => (theme.isDark ? '1px solid #ffffff47' : 'none') }; 
`
const BackArrowContainer = styled(HistoryLink)`
  // background: rgba(255, 255, 255, 0.6);
  padding: 51px 24px 0;
  border-radius: 12px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`
const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.colors.text};
`



/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody ({ children }: { children: React.ReactNode }) {
  const {pathname} = useLocation()
  return (
    <BodyGradiantWrapper>
      <FixedPosition>
        {
          (pathname.includes('stationadd') || pathname.includes('stationremove')) && (
            <BackArrowContainer to="/stationpool">
              <StyledArrowLeft />
            </BackArrowContainer>
          )
        }
        <BodyWrapper>{ children }</BodyWrapper>
      </FixedPosition>
    </BodyGradiantWrapper>
  )
}

