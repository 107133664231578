
export const config =  [
  {
    label: "Swap",
    href: "/swap",
  },
  {
    label: "Farm",
   // disabled: true
    href: 'https://farm.hurricaneswap.com/farms'
  },
  {
    label: "Transfer",
    children: [
      {
        label: "Station",
        href: "/stationpool",
      },
      {
        label: "Bridge",
        disabled: true
      },
    ]
  },
  {
    label: "Info",
    disabled: true
  },

];

export default config
