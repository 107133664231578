import React, { useMemo, useState } from 'react'
import { AVAX, Currency, ETHER, inputOutputComparator, Token } from '@glhf-libs/sdk'
import { useHistory, useLocation } from 'react-router'
import {CloseIcon1, SearchInput} from '@hurricaneswap/uikit'
import { Search } from 'react-feather'
import styled, {css} from 'styled-components'
import { inherits } from 'util'
import CurrencyList from 'components/SearchModal/CurrencyList'
import { filterTokens } from '../SearchModal/filtering'
import { isAddress } from '../../utils'
import { useAllTokens, useToken } from '../../hooks/Tokens'
import useTheme from '../../hooks/useTheme'
import { useActiveWeb3React } from '../../hooks'
import useMobile from '../../hooks/useMobile'

const Input = styled.input`
  width: 100%;
  // max-width: 204px;
  background:none;  
  outline:none;  //去掉聚焦时的蓝色边框
  border:None;

  height:100%;
  margin-left:5px;
  font-size:16px;
  color:${({theme})=>(theme.isDark ? '#8D80D5':' #4D3C55') } ;
  &::placeholder {
    color:${({theme})=>(theme.isDark ? '#FFFFFF':'rgba(77, 60, 85, 0.25)')};
  };
  &::-webkit-input-placeholder {
    color:${({theme})=>(theme.isDark ? '#FFFFFF':'rgba(77, 60, 85, 0.25)')};
  }
`
const Container = styled.div<{ifMobileForNav: boolean}>`
  position:absolute;
  top:38px;
  left:-1px;
  right:-1px;
  border: solid 1px ${ ({ ifMobileForNav, theme }) => (ifMobileForNav ? 'none' : theme.colors.borderColor) };
  background:  ${ ({ theme }) => {
  return (theme.colors.cardBackground)
} };
  border-top:none;
  border-bottom-left-radius:8px;
  border-bottom-right-radius:8px;
  box-sizing:content-box
`


const Info = styled.div`
  width:100%;
  height:50px;
  text-align:center;
  line-height:50px;
  color:${({theme})=>(theme.isDark ?  'rgba(255, 255, 255, 0.65)': 'rgba(77, 60, 85, 0.65);')}
`

const SearchTokens = () => {
  const {push} = useHistory()
  const {pathname} = useLocation()
  const {chainId} = useActiveWeb3React()
  const [searchQuery, setSearchQuery] = useState<string> ('')
  const [isBottom, setisBottom] = useState<boolean> (false)
  const [show, setshow] = useState<boolean> (false)
  const allTokens = useAllTokens ()
  const isAddressSearch = isAddress (searchQuery)
  const searchToken = useToken (searchQuery)
  const theme =useTheme()
  const info = `Sorry, we can't find "${ searchQuery }"`
  const ifMobileForNav = useMobile(1200)

  const filteredTokens: Token[] = useMemo (() => {
    if (isAddressSearch) return searchToken ? [searchToken] : []
    return filterTokens ([ETHER, ...Object.values (allTokens)], searchQuery)
  }, [isAddressSearch, searchToken, allTokens, searchQuery])

  const handleChange = (e) => {
    const value = e.target.value
    setSearchQuery (value)
    if (value.length !== 0) {
      setshow (true)
      setisBottom (true)
    } else {
      setshow (false)
      setisBottom (false)
    }
  }


  const dismiss = ()=>{
    const closeIcon: any = window.document.querySelectorAll('#menu-search #react-burger-cross-btn')[0]
    if(closeIcon){
      closeIcon.click()
    }
  }

  const handleCurrencySelect = (e) => {
    if(pathname.includes('station')){
      if(e === ETHER){
        push(`/stationadd/${ chainId }/ETH`)
      }else{
        push(`/stationadd/${ chainId }/${e.address}`)
      }
    }
    if(!pathname.includes('station')){
      if(e === ETHER){
        push(`/swap?inputCurrency=AVAX`)
      }else{
        push(`/swap?inputCurrency=${e.address}`)
      }
    }



    dismiss()
  }
  // const selectedCurrency = ()=>{}

  return (
    // @ts-ignore
    <SearchInput inputContainerStyle={ifMobileForNav && css`>div{background: none!important; div{color: #6425C5!important;}} ;background: none!important; border: none!important; box-shadow: none!important;`} isBottom={ isBottom } theme={theme} >
      <Input width='100%' onChange={handleChange} placeholder="Search token" value={searchQuery}/>
      {show && <Container ifMobileForNav={ifMobileForNav}>

    {filteredTokens.length!==0 ?
     // <div style={{minHeight:'300px',backgroundColor:'white'}}>
      <CurrencyList
        height={260}
        showETH={false}
        currencies={filteredTokens}
        onCurrencySelect={handleCurrencySelect}
        otherCurrency={null}
        selectedCurrency={null}
        fixedListRef={undefined}
        itemSize={40}
      />
   //   </div>
        : <Info>Sorry! No result </Info>}
        </Container>  }
      {

        (show && !ifMobileForNav) && <CloseIcon1 style={{cursor: 'pointer'}} isDark = {theme.isDark} onClick={()=>{setSearchQuery('');setshow(false);setisBottom(false) }}/>
      }
    </SearchInput>

  )
}

export default SearchTokens
