import { Currency, ETHER, Token } from '@glhf-libs/sdk'
import React, { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import CoinLogo from '../pancake/CoinLogo'
import AVAX_JSON from '../../constants/token/avax.json'


const tokens = AVAX_JSON.tokens

const getTokenLogoURL = (address: string) =>
  `https://raw.githubusercontent.com/ava-labs/bridge-tokens/main/avalanche-tokens/${address}/logo.png`


const StyledBnbLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`
export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {

  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  const {chainId} = useWeb3React()
  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, `/images/coins/${currency?.symbol ?? 'token'}.png`, getTokenLogoURL(currency.address)]
      }

      return [`/images/coins/${currency?.symbol ?? 'token'}.png`, getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  if (currency === ETHER) {
    // todo 判断是bnb还是avax
    if(chainId === 256 || chainId === 128){
      return <StyledBnbLogo src="https://www.gemini.com/images/currencies/icons/default/ht.svg" size={size} style={style}/>
    }

    if(chainId === 43114 || chainId === 43113){
      return <StyledBnbLogo src="https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png" size={size} style={style}/>
    }

    if(chainId === 56 || chainId === 97){
      return <StyledBnbLogo src="/images/coins/bnb.png" size={size} style={style} />
    }

    if(chainId === 1){
      return <StyledBnbLogo src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png" size={size} style={style} />
    }

    return <StyledBnbLogo src="https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png" size={size} style={style}/>

  }

  if(currency?.symbol === 'WAVAX') {
    return <StyledBnbLogo src="https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png" size={size} style={style}/>
  }
  if(currency?.symbol === 'HCT') {
    // @ts-ignore
    return <StyledBnbLogo src="/images/coins/hct.jpg" size={size} style={style} />
  }

  // 从token json列表里获取图片
  // @ts-ignore
  const filteredResult = tokens.filter(i=>i?.symbol.includes(currency?.symbol))

  if(filteredResult && filteredResult.length > 0){
    const src = filteredResult[0].logoURI
    return <StyledBnbLogo src={src} size={size} style={style}/>
  }

  return (currency as any)?.symbol ? (
    <CoinLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
  ) : (
    <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
  )
}
