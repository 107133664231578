// @ts-nocheck
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { ThemeContext }  from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Navbar } from '@hurricaneswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useTheme from 'hooks/useTheme'
import useGetPriceData from 'hooks/useGetPriceData'
import links from './config'
import useAuth from '../../hooks/useAuth'
import UserTokens from '../UserTokens'
import SearchTokens from '../SearchTokens'
import useMobile from '../../hooks/useMobile'
import i18next from '../../i18n'

// {
//   account: "0xbdda50183d817c3289f895a4472eb475967dc980",
//     login: noop,
//   logout: noop,
//   isDark: false,
//   toggleTheme: noop,
//   langs,
//   setLang: noop,
//   currentLang: "EN",
//   cakePriceUsd: 0.023158668932877668,
//   links,
//   profile: null,
// }

const Menu: React.FC = (props) => {
  const {t, i18n} = useTranslation()
  const { account, activate, deactivate } = useWeb3React()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme, theme } = useTheme()
  const priceData = useGetPriceData()
  const { login, logout } = useAuth()
  const ifMobileForNav = useMobile(1200)

  useEffect(()=>{
    window.onscroll = null;
  }, [])

  const selectLang = (e)=>{
    setSelectedLanguage(e);
    i18next.changeLanguage(e.code)
  }

 /* const currentLng = useMemo(()=>{
    return i18n?.language ? i18n?.language.toUpperCase().split('-')[0] : 'EN'
  }, [i18n.language]) */
  const currentLng = "EN"

  return (
    // <UikitMenu
    <Navbar
      ifMobile={ifMobileForNav}
      links={links}
      account={account || undefined}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLng || ''}
      langs={allLanguages}
      setLang={selectLang}
      t={t}
      // @ts-ignore
      coinList = {UserTokens}
      SearchToken={ifMobileForNav ? null : SearchTokens}
      // style={{ backdropFilter: 'blur(5px)', transition: 'all linear 0.1s', background: `rgba(${isDark ? '0,0,0,' : '255,255,255,'}${ scroll.top / 10 > 0.8 ? 0.8 : scroll.top / 10 })`}}
      style={{ backdropFilter: 'blur(5px)', transition: 'all linear 0.1s', background: `${theme.colors.gradients.backgroundCover} fixed`, opacity: 0.85}}
      /* cakePriceUsd={cakePriceUsd} */
      /* cakePriceLink={`https://bscscan.com/token/${pantherAddress}`} */
      /* profile={profile} */
      {...props}
    />
  )
}

export default Menu
