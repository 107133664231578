import React, { useContext, useEffect, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { Pair } from '@glhf-libs/sdk'
import { useSelector } from 'react-redux'
import { Button, CardBody, Text, useModal, Alert } from '@hurricaneswap/uikit'
import FullPositionCard, { StationFullPositionCard } from 'components/PositionCard'
import { useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks'
import { StyledInternalLink, TYPE } from 'components/Shared'
import { TransparentCard as LightCard } from 'components/Card'
import { RowBetween } from 'components/Row'
import { AutoColumn } from 'components/Column'

import { useActiveWeb3React } from 'hooks'
import { usePairs } from 'data/Reserves'
import { toV2LiquidityToken, useStationTrackedTokenPairs } from 'state/user/hooks'
import { Dots } from 'components/swap/styleds'
import TranslatedText from 'components/TranslatedText'
import PageHeader from 'components/PageHeader'
import AppBody from '../AppBody'
import ChooseChain from '../../components/ChooseChain'
import { AppState } from '../../state'
import StationNotAllowedAlert from '../../components/StationNotAllowedAlert'


const { body: Body } = TYPE

export default function StationPool () {
  const theme = useContext (ThemeContext)
  const { account, chainId } = useActiveWeb3React ()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useStationTrackedTokenPairs ()
  const tokenPairsWithLiquidityTokens = useMemo (
    () => trackedTokenPairs.map ((tokens) => ({ liquidityToken: toV2LiquidityToken (tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo (() => tokenPairsWithLiquidityTokens.map ((tpwlt) => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator (
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo (
    () =>
      tokenPairsWithLiquidityTokens.filter (({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan ('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs (liquidityTokensWithBalances.map (({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some ((V2Pair) => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))


  const [isOpen, setIsOpen] = useState(false)
  // const [onPresent1] = useModal (<ChooseChain isOpen={isOpen} portalId={portalId} history={ history }/>, false)

  const openModal = () => {
    setIsOpen(true)
    // onPresent1()
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const onDismiss = ()=>{
    return closeModal()
  }

  const filterAllowPairsWithLiquidity = useMemo(()=>(allV2PairsWithLiquidity.filter(i=>i)), [allV2PairsWithLiquidity])

  const {allowStation: stationStatus, allowSwap, allowLiquidity} = useSelector<AppState, AppState['station']>((state) => state.station)

  const [showNotAllowedAlert, closeNotAllowedAlert]: any = useModal(<StationNotAllowedAlert/>)

  useEffect(()=>{
    setTimeout(showNotAllowedAlert, 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <AppBody>
        <div id="station" >
          <ChooseChain onDismiss={onDismiss} isOpen={isOpen} portalId="station"/>
        </div>
        <PageHeader title="Station-LP" description="Add liquidity to receive HURRICANE-LP tokens">
          <Button id="join-pool-button" as='button' onClick={ openModal } disabled={ !stationStatus } width='100%'>
            <TranslatedText
              translationId={ 100 }>{ stationStatus ? 'Select Chain' : 'Station Closed' }</TranslatedText>
          </Button>
        </PageHeader>
        <AutoColumn gap="lg" justify="center">
          <CardBody style={ { width: '100%' } } p='24px 24px 48px'>
            <AutoColumn gap="12px" style={ { width: '100%' } }>
              <RowBetween padding="0 8px">
                <Text color={ theme.colors.text }>
                  <TranslatedText translationId={ 102 }>Your Station Liquidity</TranslatedText>
                </Text>
              </RowBetween>

              { !account ? (
                <LightCard padding="40px">
                  <Body color={ theme.colors.textDisabled } textAlign="center">
                  Connect to a wallet to view your liquidity.
                  </Body>
                </LightCard>
              ) : v2IsLoading ? (
                <LightCard padding="40px">
                  <Body color={ theme.colors.textDisabled } textAlign="center">
                  <Dots>Loading</Dots>
                  </Body>
                </LightCard>
              ) : filterAllowPairsWithLiquidity?.length > 0 ? (
                <>
                  { filterAllowPairsWithLiquidity.map ((v2Pair) => (
                    <StationFullPositionCard key={ v2Pair.liquidityToken.address } pair={ v2Pair }/>
                  )) }
                </>
              ) : (
                <LightCard padding="40px">
                  <Body color={ theme.colors.textDisabled } textAlign="center">
                  <TranslatedText translationId={ 104 }>No liquidity found.</TranslatedText>
                  </Body>
                </LightCard>
              ) }

              {
                stationStatus ? (
                  null
                ) : (
                  <div>
                    <Text fontSize="14px" style={ { padding: '.5rem 0 .5rem 0' } }>
                      Please wait for station opened
                    </Text>
                  </div>)
              }

            </AutoColumn>
          </CardBody>
        </AutoColumn>
      </AppBody>
    </>
  );
}
