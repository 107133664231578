import { Currency } from '@glhf-libs/sdk'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import useLast from '../../hooks/useLast'
import { useSelectedListUrl } from '../../state/lists/hooks'
import Modal from '../Modal'
import { CurrencySearch } from './CurrencySearch'
import { ListSelect } from './ListSelect'
import { PairSearch } from './PairSearch'

const AbsoulteSection = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  border: 1px solid black;
  height: 50vh;
  z-index: 9999;
  background-color: #000;
`


interface CurrencySearchModalProps {
  isOpen: boolean
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currencyA: Currency, currencyB: Currency) => void
  otherSelectedCurrency?: Currency | null
  // eslint-disable-next-line react/no-unused-prop-types
  showCommonBases?: boolean
  portalId?: string
}

export default function PairSearchModal({
                                              isOpen,
                                              onDismiss,
                                              onCurrencySelect,
                                              selectedCurrency,
                                              otherSelectedCurrency,
                                              portalId
                                            }: CurrencySearchModalProps) {
  const [listView, setListView] = useState<boolean>(false)
  const lastOpen = useLast(isOpen)

  useEffect(() => {
    if (isOpen && !lastOpen) {
      setListView(false)
    }
  }, [isOpen, lastOpen])

  const handleCurrencySelect = useCallback(
    (currencyA: Currency, currencyB: Currency) => {
      onCurrencySelect(currencyA, currencyB)
      onDismiss()
    },
    [onDismiss, onCurrencySelect]
  )

  const handleClickChangeList = useCallback(() => {
    setListView(true)
  }, [])
  const handleClickBack = useCallback(() => {
    setListView(false)
  }, [])

  const selectedListUrl = useSelectedListUrl()
  const noListSelected = !selectedListUrl


  return (
    <Modal portalId={portalId} isOpen={isOpen} onDismiss={onDismiss} maxHeight={90} minHeight={listView ? 40 : noListSelected ? 0 : 80}>
      {listView ? (
        <ListSelect onDismiss={onDismiss} onBack={handleClickBack} />
      ) : noListSelected
        ? (
          <PairSearch
            isOpen={isOpen}
            onDismiss={onDismiss}
            onCurrencySelect={handleCurrencySelect}
            onChangeList={handleClickChangeList}
            selectedCurrency={selectedCurrency}
            otherSelectedCurrency={otherSelectedCurrency}
            showCommonBases={false}
          />
        ) : (
          <PairSearch
            isOpen={isOpen}
            onDismiss={onDismiss}
            onCurrencySelect={handleCurrencySelect}
            onChangeList={handleClickChangeList}
            selectedCurrency={selectedCurrency}
            otherSelectedCurrency={otherSelectedCurrency}
            showCommonBases={false}
          />
        )}
    </Modal>
  )
}
