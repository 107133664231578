import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const defaultLng: any = window.localStorage.getItem('i18nextLng') ? window.localStorage.getItem('i18nextLng') : 'en'

i18next
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `./locales/{{lng}}.json`
    },
    react: {
      useSuspense: true
    },
    // fallbackLng: defaultLng,
    fallbackLng: 'en',
   // whitelist: ['en', 'zh'],
   // preload: ['en', 'zh'],
    whitelist: ['en'],
    preload: ['en'],
    keySeparator: false,
    interpolation: { escapeValue: false }
  })

export default i18next
