import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import {IoCloseCircle, IoCloseOutline} from 'react-icons/io5'
import { Currency, currencyEquals, ETHER, Token, TokenAmount, WETH } from '@glhf-libs/sdk'
import {
  Button,
  CardBody,
  AddIcon,
  Text as UIKitText,
  ChevronDownIcon,
  Flex,
  ToastContainer,
  useModal
} from '@hurricaneswap/uikit'
import { Link as HistoryLink, RouteComponentProps, useHistory, useLocation, useParams } from 'react-router-dom'
import { LightCard } from 'components/Card'
import { AutoColumn, ColumnCenter } from 'components/Column'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import CardNav from 'components/CardNav'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { StationAddRemoveTabs } from 'components/NavigationTabs'
import { MinimalPositionCard } from 'components/PositionCard'
import Row, { RowBetween, RowFlat } from 'components/Row'

import { PairState } from 'data/Reserves'
import { useActiveWeb3React } from 'hooks'
import { useAllTokens, useCurrency } from 'hooks/Tokens'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { Field } from 'state/mint/actions'
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from 'state/mint/hooks'

import { useTransactionAdder } from 'state/transactions/hooks'
import { useIsExpertMode, useUserDeadline, useUserSlippageTolerance } from 'state/user/hooks'
import { TYPE } from 'components/Shared'
import { calculateGasMargin, calculateSlippageAmount, getRouterContract } from 'utils'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { currencyId } from 'utils/currencyId'
import Pane from 'components/Pane'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AppBody from '../AppBody'
import { Dots, Wrapper } from '../StationPool/styleds'
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom'
import { PoolPriceBar } from './PoolPriceBar'
import {useRouterAddress} from "../../hooks/useRouterAddress"
import { AppState } from '../../state'
import { useUpdateInputCurrency, useUpdateOutputCurrency } from '../../state/trading/hooks'
import ChooseChain from '../../components/ChooseChain'
import { useNetworkName } from '../../hooks/useBaseCoin'
import useStationDefaultInput from '../../hooks/useStationDefaultInput'
import useStationStatus from '../../hooks/useStationStatus'
import StationTradingPairSelect from '../../components/StationTradingPairsSelect'
import {WHITE_LIST} from '../../constants/index'
import StationWhiteListAlert from '../../components/StationWhiteListAlert'

const { italic: Italic } = TYPE

const SwitchNetworkWrapper = styled.div`
  padding: 24px 0px 36px; 
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StationActiveBox = styled.div`
  width: 55px;
  height: 22px;
  line-height: 22px;
  background: #39D98A;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
  text-align: center;
`

const StationDisableBox = styled.div`
  width: 55px;
  height: 22px;
  line-height: 22px;
  background: #E3E4EB;
  border-radius: 8px;
  color: rgba(77, 60, 85, 0.12);
  font-size: 12px;
  text-align: center; 
`


export default function StationAddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB },
  },
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const { account, chainId, library } = useActiveWeb3React()
  const currencyA: any = useCurrency(currencyIdA)
  const currencyB: any = useCurrency(currencyIdB)

  const {allowStation: stationStatus, error: contractError} = useSelector<AppState, AppState['station']>((state) => state.station)

  const oneCurrencyIsWETH = Boolean(
    chainId &&
      ((currencyA && currencyEquals(currencyA, WETH[chainId])) ||
        (currencyB && currencyEquals(currencyB, WETH[chainId])))
  )
  const expertMode = useIsExpertMode()

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)
  const ROUTER_ADDRESS = useRouterAddress(chainId)
  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  const [deadline] = useUserDeadline() // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance() // custom from users
  const [txHash, setTxHash] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field]),
      }
    },
    {}
  )

  const atMaxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      }
    },
    {}
  )


  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], ROUTER_ADDRESS)
  const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], ROUTER_ADDRESS)

  const addTransaction = useTransactionAdder()

  const [toasts, setToasts]: any = useState([]);
  const onRemove = ()=>{setToasts([])}

  async function onAdd() {
    if (!chainId || !library || !account) return
    const router = getRouterContract(chainId, library, account)

    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB) {
      return
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? 0 : allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? 0 : allowedSlippage)[0],
    }

    const deadlineFromNow = Math.ceil(Date.now() / 1000) + deadline

    let estimate
    let method: (...args: any) => Promise<TransactionResponse>
    let args: Array<string | string[] | number>
    let value: BigNumber | null
    if (currencyA === ETHER || currencyB === ETHER) {
      const tokenBIsETH = currencyB === ETHER
      estimate = router.estimateGas.addLiquidityAVAX
      method = router.addLiquidityAVAX
      args = [
        wrappedCurrency(tokenBIsETH ? currencyA : currencyB, chainId)?.address ?? '', // token
        (tokenBIsETH ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
        amountsMin[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
        amountsMin[tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
        account,
        deadlineFromNow,
      ]
      value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString())
    } else {
      estimate = router.estimateGas.addLiquidity
      method = router.addLiquidity
      args = [
        wrappedCurrency(currencyA, chainId)?.address ?? '',
        wrappedCurrency(currencyB, chainId)?.address ?? '',
        parsedAmountA.raw.toString(),
        parsedAmountB.raw.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadlineFromNow,
      ]
      value = null
    }

    setAttemptingTxn(true)
    // const aa = await estimate(...args, value ? { value } : {})
    await estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit) =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
        }).then((response) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary: `Add ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
              currencies[Field.CURRENCY_A]?.symbol
            } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencies[Field.CURRENCY_B]?.symbol}`,
          })

          setTxHash(response.hash)
        })
      )
      .catch((e) => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (e?.code !== 4001) {
          if(e?.data?.code === 3){

            const errorToast = {
              id: `id-${e?.data?.code}`,
              title: `Error`,
              description: (
                <AutoColumn>
                  {/* <UIKitText mt='10px' mb='10px'>Cross-chain token liquidity-adding does not support yet.</UIKitText> */}
                  <UIKitText mt='10px' mb='10px'>{e?.data?.message}</UIKitText>
                  <Button scale='sm' as={HistoryLink} to='/stationpool' >Reselect Tokens</Button>
                </AutoColumn>
              ),
              action: {
                text: "Reselect Tokens",
                url: `/stationpool`,
              },
              type: 'danger',
            };

            setToasts([errorToast])
          }
          console.error(e)
        }
      })
  }

  const modalHeader = () => {
    return noLiquidity ? (
      <AutoColumn gap="20px">
        <LightCard mt="20px" borderRadius="20px">
          <RowFlat>
            <UIKitText fontSize="48px" mr="8px">
              {`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol}`}
            </UIKitText>
            <DoubleCurrencyLogo
              currency0={currencies[Field.CURRENCY_A]}
              currency1={currencies[Field.CURRENCY_B]}
              size={30}
            />
          </RowFlat>
        </LightCard>
      </AutoColumn>
    ) : (
      <AutoColumn gap="20px">
        <RowFlat style={{ marginTop: '20px' }}>
          <UIKitText fontSize="48px" mr="8px">
            {liquidityMinted?.toSignificant(6)}
          </UIKitText>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={30}
          />
        </RowFlat>
        <Row>
          <UIKitText fontSize="24px">
            {`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol} Pool Tokens`}
          </UIKitText>
        </Row>
        <Italic fontSize={12} textAlign="left" padding="8px 0 0 0 ">
          {`Output is estimated. If the price changes by more than ${
            allowedSlippage / 100
          }% your transaction will revert.`}
        </Italic>
      </AutoColumn>
    )
  }

  const {stationChainId} = useSelector<AppState, AppState['station']>((state) => state.station)

  const defaultInput = useStationDefaultInput()

  const pendingText = `Supplying ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencies[Field.CURRENCY_A]?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencies[Field.CURRENCY_B]?.symbol}`


  const handlePairsSelect = useCallback(
    (currA: Currency, currB: Currency) => {
      const newCurrencyIdA = currencyId(currA)
      const newCurrencyIdB = currencyId(currB)
      history.push(`/stationadd/${stationChainId}/${newCurrencyIdA}/${newCurrencyIdB}`)
    },
    [ history, stationChainId]
  )

  const handleCurrencyASelect = useCallback(
    (currA: Currency) => {
      const newCurrencyIdA = currencyId(currA)
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/stationadd/${stationChainId}/${currencyIdB}/${currencyIdA}`)
      } else {
        history.push(`/stationadd/${stationChainId}/${newCurrencyIdA}/${currencyIdB}`)
      }
    },
    [currencyIdB, history, currencyIdA, stationChainId]
  )
  const handleCurrencyBSelect = useCallback(
    (currB: Currency) => {
      const newCurrencyIdB = currencyId(currB)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/stationadd/${stationChainId}/${currencyIdB}/${newCurrencyIdB}`)
        } else {
          history.push(`/stationadd/${stationChainId}/${newCurrencyIdB}`)
        }
      } else {
        history.push(`/stationadd/${stationChainId}/${currencyIdA || defaultInput}/${newCurrencyIdB}`)
      }
    },
    [currencyIdA, history, currencyIdB, stationChainId, defaultInput]
  )

  const a = useStationDefaultInput()

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
    }
    setTxHash('')
  }, [onFieldAInput, txHash])


  const inputLoad = useUpdateInputCurrency()
  const outputLoad = useUpdateOutputCurrency()

  useEffect(()=>{
    inputLoad(currencies[Field.CURRENCY_A] )
    outputLoad(currencies[Field.CURRENCY_B] )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencies[Field.CURRENCY_A], currencies[Field.CURRENCY_B]])

  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const onDismiss = ()=>{
    return closeModal()
  }

  const networkName = useNetworkName()
  const {t} = useTranslation()

  const params: any = useParams ()
  const allTokens = useAllTokens ()

  const ifBisNotUsdt = useMemo(()=>{
    return false;
    // 下面的逻辑暂时不需要
    if (params?.currencyIdA && params?.currencyIdB) {
      const tokenA = allTokens[params?.currencyIdA]
      const tokenB = allTokens[params?.currencyIdB]
      if(tokenA && tokenB){
        return tokenB?.symbol !== 'USDT'
      }
      return false
    }
    return false
  }, [params, allTokens])

  const ifAccountIsInWhiteList = useMemo(()=>{
    if(account){
      return WHITE_LIST.includes(account.toString())
    }
    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId])

  const [showNoInWhiteListAlert, closeNoInWhiteListAlert]: any = useModal(<StationWhiteListAlert/>)

  const handleSupply = useCallback(() => {

    if(ifAccountIsInWhiteList){
      if (expertMode) {
        onAdd()
      } else {
        setShowConfirm(true)
      }
    }else{
      showNoInWhiteListAlert()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ifAccountIsInWhiteList])

  const approveACallbackLoad = useCallback(()=>{
    if(ifAccountIsInWhiteList){
      approveACallback()
    }else{
      showNoInWhiteListAlert()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ifAccountIsInWhiteList, parsedAmounts[Field.CURRENCY_A]])

  const approveBCallbackLoad = useCallback(()=>{
    if(ifAccountIsInWhiteList){
      approveBCallback()
    }else{
      showNoInWhiteListAlert()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ifAccountIsInWhiteList, parsedAmounts[Field.CURRENCY_B]])

  const modalBottom = () => {
    if(ifAccountIsInWhiteList){
      return (
        <ConfirmAddModalBottom
          price={price}
          currencies={currencies}
          parsedAmounts={parsedAmounts}
          noLiquidity={noLiquidity}
          onAdd={onAdd}
          poolTokenPercentage={poolTokenPercentage}
        />
      )
    }

    return (
      <UIKitText textAlign='center' mt='45px'>{t('HurricaneStation is not open yet Coming soon in V2')}</UIKitText>
    )
  }

  const {goBack, push} = useHistory()

  useEffect(()=>{
   if(account && chainId && params?.chainId && (chainId.toString() !== params?.chainId.toString())){
     goBack()
    }else if(!account){
     push('/stationpool')
   }
  }, [push, goBack, account, params?.chainId, chainId])

  return (
    <>
      <AppBody>
        <StationAddRemoveTabs adding />
        <Wrapper id="station-page">
          <TransactionConfirmationModal
            isOpen={showConfirm}
            onDismiss={handleDismissConfirmation}
            attemptingTxn={attemptingTxn}
            hash={txHash}
            content={() => (
              <ConfirmationModalContent
                title={noLiquidity ? 'You are creating a pool' : 'You will receive'}
                onDismiss={handleDismissConfirmation}
                topContent={modalHeader}
                bottomContent={modalBottom}
              />
            )}
            pendingText={pendingText}
          />
          <CardBody p='24px 24px 48px'>
            <AutoColumn gap="16px">
              {noLiquidity && (
                <ColumnCenter>
                  <Pane>
                    <AutoColumn gap="12px">
                      <UIKitText>You are the first liquidity provider.</UIKitText>
                      <UIKitText>The ratio of tokens you add will set the price of this pool.</UIKitText>
                      <UIKitText>Once you are happy with the rate click supply to review.</UIKitText>
                    </AutoColumn>
                  </Pane>
                </ColumnCenter>
              )}

              <div id="station" >
                <ChooseChain onDismiss={onDismiss} isOpen={isOpen} portalId="station" />
              </div>

              {/* station pairs 下拉框 */}
              <StationTradingPairSelect portalId="station-page" onCurrencySelect={handlePairsSelect}/>

              <CurrencyInputPanel
                disableCurrencySelect
                portalId="station-page"
                value={formattedAmounts[Field.CURRENCY_A]}
                onUserInput={onFieldAInput}
                onMax={() => {
                  onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
                }}
                onCurrencySelect={handleCurrencyASelect}
                showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                currency={currencies[Field.CURRENCY_A]}
                id="add-liquidity-input-tokena"
                showCommonBases={false}
              />
              <ColumnCenter>
                <AddIcon color="textSubtle" />
              </ColumnCenter>
              <CurrencyInputPanel
                disableCurrencySelect
                portalId="station-page"
                value={formattedAmounts[Field.CURRENCY_B]}
                onUserInput={onFieldBInput}
                onCurrencySelect={handleCurrencyBSelect}
                onMax={() => {
                  onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
                }}
                showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                currency={currencies[Field.CURRENCY_B]}
                id="add-liquidity-input-tokenb"
                showCommonBases={false}
              />
              {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
                <div>
                  <UIKitText
                    style={{ textTransform: 'uppercase', fontWeight: 600 }}
                    color="textSubtle"
                    fontSize="12px"
                    mb="2px"
                  >
                    {noLiquidity ? 'Initial prices and pool share' : 'Prices and pool share'}
                  </UIKitText>
                  <Pane>
                    <PoolPriceBar
                      currencies={currencies}
                      poolTokenPercentage={poolTokenPercentage}
                      noLiquidity={noLiquidity}
                      price={price}
                    />
                  </Pane>
                </div>
              )}

              {!account ? (
                <ConnectWalletButton width='100%' />
              ) : (
                <AutoColumn gap="md">
                  {(approvalA === ApprovalState.NOT_APPROVED ||
                    approvalA === ApprovalState.PENDING ||
                    approvalB === ApprovalState.NOT_APPROVED ||
                    approvalB === ApprovalState.PENDING) &&
                    isValid && (
                      <RowBetween>
                        {approvalA !== ApprovalState.APPROVED && (currencies[Field.CURRENCY_A]?.symbol) && (
                          <Button
                            onClick={approveACallbackLoad}
                            disabled={approvalA === ApprovalState.PENDING || !parsedAmounts[Field.CURRENCY_A]}
                            style={{ width: approvalB !== ApprovalState.APPROVED ? '48%' : '100%' }}
                          >
                            {approvalA === ApprovalState.PENDING ? (
                              <Dots>Approving {currencies[Field.CURRENCY_A]?.symbol}</Dots>
                            ) : (
                              `Approve ${currencies[Field.CURRENCY_A]?.symbol}`
                            )}
                          </Button>
                        )}
                        {approvalB !== ApprovalState.APPROVED && (currencies[Field.CURRENCY_B]?.symbol) && (
                          <Button
                            onClick={approveBCallbackLoad}
                            disabled={approvalB === ApprovalState.PENDING || !parsedAmounts[Field.CURRENCY_B]}
                            style={{ width: approvalA !== ApprovalState.APPROVED ? '48%' : '100%' }}
                          >
                            {approvalB === ApprovalState.PENDING ? (
                              <Dots>Approving {currencies[Field.CURRENCY_B]?.symbol}</Dots>
                            ) : (
                              `Approve ${currencies[Field.CURRENCY_B]?.symbol}`
                            )}
                          </Button>
                        )}
                      </RowBetween>
                    )}
                  <Button
                    onClick={handleSupply}
                    variant={
                      Boolean((error || contractError || !stationStatus)) && !isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]
                        ? 'danger'
                        : 'primary'
                    }
                    width="100%"
                  >
                    {(error || contractError || !stationStatus) ? (error || contractError || (stationStatus ? 'Station Loading...' : 'Supply')) : 'Supply'}
                  </Button>
                </AutoColumn>
              )}
            </AutoColumn>
          </CardBody>
        </Wrapper>
      </AppBody>
      {pair && !noLiquidity && pairState !== PairState.INVALID ? (
        <AutoColumn style={{ maxWidth: '464px', width: '100%', marginTop: '1rem' }}>
          <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
        </AutoColumn>
      ) : null}

      <ToastContainer toasts={toasts} onRemove={onRemove}/>
    </>
  )
}
