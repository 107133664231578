import React, { useCallback, useMemo, useState } from 'react'
import { Flex, Button, Text,ChevronDownIcon } from '@hurricaneswap/uikit'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import PairSearchModal from '../SearchModal/PairSearchModal'
import { useAllTokens } from '../../hooks/Tokens'
import { MenuItem } from '../SearchModal/styleds'
import CurrencyLogo from '../CurrencyLogo'


const SelectPair = styled(Button)`
background: transparent;
border: 1px solid ${({theme})=>(theme?.colors.text)};
width: 100%;
display: flex;
justify-content:space-between;
padding: 0px 16px;


`

const StationTradingPairSelect = ({ portalId, onCurrencySelect }) => {
  const [modalOpen, setModalOpen] = useState (false)
  const params: any = useParams ()
  const allTokens = useAllTokens ()


  const tokenPairs = useMemo (() => {
    if (params?.currencyIdA && params?.currencyIdB) {
      const tokenA = allTokens[params?.currencyIdA]
      const tokenB = allTokens[params?.currencyIdB]
      if(tokenA && tokenB){
        return (
          <Flex>
            <Flex flexDirection='row' alignItems='center'>
              <CurrencyLogo  style={{position: 'relative', zIndex: 1}} currency={tokenA} size="24px" />
              <CurrencyLogo style={{transform: 'translate3d(-10px, 0, 0)', position: 'relative', zIndex: 0}} currency={tokenB} size="24px" />
            </Flex>
            <Text>{ `${tokenA?.symbol}/${tokenB?.symbol}` }</Text>
          </Flex>
        )
      }
      return null
    }
    return null
  }, [params, allTokens])


  const handleDismissSearch = useCallback (() => {
    setModalOpen (false)
  }, [setModalOpen])

  return (
    <Flex p='10px 0 20px'>
      <SelectPair onClick={ () => {
        setModalOpen (true)
      } }>
        <Text>{ tokenPairs || 'Select A Pair' }</Text>
        <ChevronDownIcon/>
      </SelectPair>


      <PairSearchModal portalId={ portalId } isOpen={ modalOpen } onDismiss={ handleDismissSearch }
                       onCurrencySelect={ onCurrencySelect }/>

    </Flex>
  )
}

export default StationTradingPairSelect
