import { ChainId } from '@glhf-libs/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696', // TODO
  [ChainId.RINKEBY]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [ChainId.ROPSTEN]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [ChainId.GÖRLI]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [ChainId.KOVAN]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [ChainId.HECO_MAINNET]: '0x56171094a15B8caC4314C0F8930100B939503bd9',
  [ChainId.HECO_TESTNET]: '0xD1dbc88E6a94053B11dA07CA3E5CE24B282d3d32',
  [ChainId.BSC_MAINNET]: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
  [ChainId.BSC_TESTNET]: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
  [ChainId.AVAX_MAINNET]: '0x0FB54156B496b5a040b51A71817aED9e2927912E',
  [ChainId.AVAX_TESTNET]: '0xb465Fd2d9C71d5D6e6c069aaC9b4E21c69aAA78f',
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
