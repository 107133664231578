import { Flex, Text as UIKitText } from '@hurricaneswap/uikit'
import { IoCloseCircle, IoCloseOutline } from 'react-icons/io5'
import { BsFillExclamationCircleFill } from 'react-icons/bs'
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useTheme from '../../hooks/useTheme'


const NotInWhiteListModal = styled(Flex)`
width: 360px;
height: 400px;
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background: ${({theme})=>(theme.isDark ? 'rgba(29,20,43,0.6)' : '#fff')};
z-index: 10;
box-shadow: 0px 20px 80px rgba(120, 118, 148, 0.08);
backdrop-filter: blur(21.5519px);
border-radius: 20px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 24px 33px;
`


const defaultOnDismiss = () => null

export default ({onDismiss = defaultOnDismiss})=>{

  const {t} = useTranslation()
  const {isDark} = useTheme()

  return(
    <NotInWhiteListModal>
      <Flex width='100%' position='absolute' top='24px' alignItems='center' justifyContent='center'>
        <UIKitText>{t('Alert')}</UIKitText>
        <IoCloseOutline color={isDark ? '#FFF' : '#000'}  onClick={onDismiss} style={{cursor: 'pointer', position: 'absolute', right: '33px'}}/>
      </Flex>
      <Flex flexDirection='column' alignItems='center' justifyContent='center'>
        <div style={{position: 'relative'}} >
          <BsFillExclamationCircleFill size='87.5px' fill='#8D80D5'/>
          <div style={{position: 'absolute', bottom: 0, width: '90px', height: '6.67px', background: 'rgba(187, 187, 187, 0.5)', filter: 'blur(5.43656px)'}}/>
        </div>
        <UIKitText textAlign='center' mt='45px'>{t('The HurricaneSwap Testnet only supports the Avalanche Fuji Testnet The Station is unavailable in Testnet')}</UIKitText>
      </Flex>
    </NotInWhiteListModal>
  )
}
