import { ChainId, JSBI, Percent, Token, WETH } from '@glhf-libs/sdk'

export const baseChainID = 43113;

export const WHITE_LIST = ['0x7C947ce72169cA14Fc110c135fFc7E0f2e40942D', '0xFaCBA6e9D7037EBe0Ac8831ce90952a06323B662', '0x5EB1Ed2f5723A09111151E6A51b3d266Bd135f76', '0xF194c424eb10Ecc81b65c0328542278545776EF0', '0x52b4e705eAA31662eCb86B12f8581ACcDff98789']

// mdex
// export const ROUTER_ADDRESS = '0xED7d5F38C79115ca12fe6C0041abb22F0A06C300'
// pancake
export const BSC_ROUTER_ADDRESS = '0xC8aBe8f5A76E2Fc316727BED81e97C4EF49Dd62e'
// panther
// export const ROUTER_ADDRESS = '0x24f7C33ae5f77e2A9ECeed7EA858B4ca2fa1B7eC'
// AVAX
// export const AVAX_ROUTER_ADDRESS = '0x748c560b297e071a078e52792E23879C9B2F6b19'
export const AVAX_ROUTER_ADDRESS = '0x7E3411B04766089cFaa52DB688855356A12f05D1'
// heco
export const HECO_ROUTER_ADDRESS = '0x72cc27Eb3AEdf2CCAe8Eb28793C56E4fEda955FC'
// heco
export const ROUTER_ADDRESS = '0x798A66d6bF7d65F2974629Df034d64aD6E2B036d'


export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}


const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WETH[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WETH[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WETH[ChainId.KOVAN]],
  [ChainId.BSC_MAINNET]: [WETH[ChainId.BSC_MAINNET]],
  [ChainId.BSC_TESTNET]: [WETH[ChainId.BSC_TESTNET]],
  [ChainId.HECO_MAINNET]: [WETH[ChainId.HECO_MAINNET]],
  [ChainId.HECO_TESTNET]: [WETH[ChainId.HECO_TESTNET]],
  [ChainId.AVAX_MAINNET]: [WETH[ChainId.AVAX_MAINNET]],
  [ChainId.AVAX_TESTNET]: [WETH[ChainId.AVAX_TESTNET]],
}

export const HCT = new Token( ChainId.AVAX_TESTNET, '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a', 18, 'HCT', 'HCT')

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET]],
  [ChainId.AVAX_TESTNET]: [HCT]
}

export const LTC = new Token(ChainId.HECO_TESTNET, '0x9cd0709FD68AFf65F047482d1407C19E5b385043', 18, 'LTC', 'LTC')
export const USDT = new Token(ChainId.HECO_TESTNET, '0xd6AA3269A1C06cB7a310A21AED06618D907e2008', 18, 'USDT', 'USDT')
export const HUSD = new Token(ChainId.HECO_TESTNET, '0xC17aa992Eec7b9d9b58e8175282Ae68C416F7118', 18, 'HUSD', 'HUSD')
export const BTC = new Token(ChainId.HECO_TESTNET, '0x86586e75E0b181999B1dCD620e58c560245826b8', 18, 'BTC', 'BTC')
export const DOT = new Token(ChainId.HECO_TESTNET, '0xE754BB537021c097239f7203e9a37fC059982304', 18, 'DOT', 'DOT')
export const FIL = new Token(ChainId.HECO_TESTNET, '0x838030272f7Cc9bb706cDaa2d20ebC9Ed246CB79', 18, 'FIL', 'FIL')

export const PNG = new Token(ChainId.AVAX_TESTNET, ZERO_ADDRESS, 18, 'PNG', 'Pangolin')
export const DAI = new Token(ChainId.AVAX_TESTNET, ZERO_ADDRESS, 18, 'DAI', 'Dai Stablecoin')
export const UNI = new Token(ChainId.AVAX_TESTNET, ZERO_ADDRESS, 18, 'UNI', 'Uniswap')
export const TOKENC = new Token(ChainId.BSC_MAINNET, '0x354a5de0b32ed7f34254ac2f9a1ddf61db19684b', 18, 'TokenC', 'TokenC')
/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
  [ChainId.AVAX_TESTNET]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], HUSD, BTC, DOT, FIL],
  [ChainId.AVAX_TESTNET]: [...WETH_ONLY[ChainId.AVAX_TESTNET]],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], HUSD, BTC, DOT, FIL],
  [ChainId.AVAX_TESTNET]: [...WETH_ONLY[ChainId.AVAX_TESTNET], HCT],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [],
  [ChainId.AVAX_TESTNET]: []
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 650
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
