import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Flex, Text } from '@hurricaneswap/uikit'
import useMobile from '../../hooks/useMobile'
import coinGroup from '../../assets/imgs/coinGroup.png'


const CardContainer = styled.div<{ifMobile: boolean}>`
  max-width: 710px;
  width: 100%;
  padding: ${({ifMobile})=>(ifMobile ? '0 10px' : '0')};
  box-sizing: border-box;
  height: 285px;
  overflow: hidden;
  position: relative;
  background: ${({theme})=>(theme.colors.swapContainer)};
  border:${ ({ theme }) => (theme.isDark ? '1px solid #ffffff47' : 'none') }; 
  border-radius: 20px;

`

const Card = styled.div<{ifMobile?: boolean}>`
  width: 100%;
  height: 100%;
  // padding: 35px 5px 0 26px;
  padding: 5px;
  background-image: url(${({ifMobile})=>(!ifMobile ? coinGroup : null)});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

`

const Circle = styled.div`
  max-width: 332px;
  max-height: 332px;
  position: relative;
  ::after{
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid black;  
  }
`

const titile = 'What\'s HurricaneStation'
const des1 = 'HurricaneStation is the first stop of asset cross-chain LPs could lock their assets in Station, generate LP tokens, and realize liquidity cross-chain through Roke Protocol to provide liquidity for HurricaneSwap'

const StationDes = () => {
  const ifMobile = useMobile()
  const {t} = useTranslation()

  return (
    <CardContainer ifMobile={ifMobile}>
      <Card ifMobile={ifMobile}>
        <Flex>
          <Flex alignItems={ifMobile?'center':'flex-start'} flexDirection='column' pt='30px' pl='21px' pr='21px' maxWidth='322px' style={{boxSizing: 'content-box'}}>
            <Text mb='28px' fontSize='20px'>{titile}</Text>
            <Text textAlign={ifMobile?'center':'left'} mb='10px' lineHeight='24px'>{t(des1)}</Text>
          </Flex>
        </Flex>
      </Card>
    </CardContainer>

  )
}

export default StationDes
