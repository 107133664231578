import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'

import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'
import { BscConnector } from './bsc/bscConnector'


const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID

// export const NETWORK_CHAIN_IDS: number[] = [56, 97, 128, 256, 43114, 43113]
export const NETWORK_CHAIN_IDS: number[] = [43113]

// if (typeof NETWORK_URL === 'undefined') {
//   throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
// }

export const network = new NetworkConnector ({
  // @ts-ignore
  urls: {
    [parseInt ('43113', 10)]: 'https://api.avax-test.network/ext/bc/C/rpc'
  },
  defaultChainId: 43113
})

let networkLibrary: Web3Provider | undefined

export function getNetworkLibrary (): Web3Provider {
  // eslint-disable-next-line no-return-assign
  return (networkLibrary = networkLibrary ?? new Web3Provider (network.provider as any))
}

export const injected = new InjectedConnector ({
  supportedChainIds: [43113]
})

export const bsc = new BscConnector ({ supportedChainIds: [43113] })



// mainnet only
export const walletconnect = new WalletConnectConnector ({
  rpc: {
    [parseInt ('43113', 10)]: 'https://api.avax-test.network/ext/bc/C/rpc',
  },
  supportedChainIds: [43113],
  // bridge: 'https://bridge.walletconnect.org',
  bridge: 'https://pancakeswap.bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 15000
})


// mainnet only
export const fortmatic = new FortmaticConnector ({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1
})

// mainnet only
export const portis = new PortisConnector ({
  dAppId: PORTIS_ID ?? '',
  networks: [1]
})

// mainnet only
export const walletlink = new WalletLinkConnector ({
  // @ts-ignore
  url: `https://mainnet.infura.io/v3/${ process.env.REACT_APP_INFURA_KEY }`,
  appName: 'Uniswap',
  appLogoUrl:
    'https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg'
})
