
export type CoinCardColor ={
    light:string;
    dark:string;
  }

export  interface Config {
    title: string;
    currency: string;
    chain: string;
    color: CoinCardColor
    address:string;
  }




const CoinToShow: Config[] = [
    {
     title: 'hct',
     currency: 'HCTIcon',
     chain: 'avax',
     address:'0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
     color:
         {
             light:'linear-gradient(148.3deg, rgba(217, 203, 255, 0.25) 7.41%, rgba(196, 130, 248, 0.49) 89.26%), #FFFFFF',
             dark:'linear-gradient(148.3deg, rgba(217, 203, 255, 0.125) 7.41%, rgba(196, 130, 248, 0.245) 89.26%), #23122A'}
     },
    {
      title: 'cake',
      currency: 'CAKEIcon',
      chain: 'bsc',
      address:'0x2542250239e4800B89e47A813cD2B478822b2385',
      color:
          {
              light:'linear-gradient(148.3deg, rgba(255, 254, 250, 0.3) 7.41%, rgba(251, 230, 188, 0.3) 89.26%), #FFFFFF;',
              dark:'linear-gradient(148.3deg, rgba(255, 245, 221, 0.102) 7.41%, rgba(255, 238, 198, 0.12) 89.26%), #101216;'}
    },
    {
      title: 'eth',
      currency: 'ETHIcon',
      chain: 'eth',
      address:'0xeFf581Ca1f9B49F49A183cD4f25F69776FA0EbF4',
      color:
          {
              light:'linear-gradient(148.3deg, rgba(203, 213, 255, 0.23) 7.41%, rgba(130, 153, 248, 0.46) 89.26%), #FFFFFF',
              dark:'linear-gradient(148.3deg, rgba(203, 213, 255, 0.115) 7.41%, rgba(130, 153, 248, 0.23) 89.26%), #101216'}
    },
    {
      title: 'uni',
      currency: 'UNIIcon',
      chain: 'eth',
      address:'0xD38a71E2021105fB8eFF71378B5f74abA8C4738F',
      color:
          {
              light:'linear-gradient(148.3deg, rgba(255, 64, 154, 0.07) 7.41%, rgba(255, 64, 154, 0.28) 89.26%), #FFFFFF',
              dark:'linear-gradient(148.3deg, rgba(255, 64, 154, 0.12) 7.41%, rgba(255, 64, 154, 0.14) 89.26%), #231229'}
    },
    {
      title: 'eps',
      currency: 'EPSIcon',
      chain: 'bsc',
      address:'0x299D57d6f674814893B8b34EB635e3add5Fab1F7',
      color:
          {
              light:' linear-gradient(148.3deg, rgba(191, 240, 255, 0.37) 7.41%, rgba(170, 229, 255, 0.66) 89.26%), #FFFFFF',
              dark:' linear-gradient(148.3deg, rgba(191, 240, 255, 0.185) 7.41%, rgba(170, 229, 255, 0.33) 89.26%), #100F1A' }
    },


  {
    title: 'link',
    currency: 'LINKIcon',
    chain: 'eth',
    address:'0xf672c3cDD3C143C05Aada34f50d4ad519558994F',
    color:
        {
            light:'linear-gradient(165.97deg, rgba(138, 176, 255, 0.17) 6.95%, rgba(134, 172, 255, 0.29) 90%), #FFFFFF',
            dark:'linear-gradient(165.97deg, rgba(138, 176, 255, 0.17) 6.95%, rgba(134, 172, 255, 0.29) 90%), #111318'}
  },
  {
    title: 'mdx',
    currency: 'MDXIcon',
    chain: 'heco',
    address:'0x22945F857030A8f3EDb8872fe76000701D640Bb7',
    color:
        {
            light:'linear-gradient(148.3deg, rgba(115, 194, 232, 0.12) 7.41%, rgba(114, 192, 230, 0.21) 89.26%), #FFFFFF',
            dark:'linear-gradient(148.3deg, rgba(115, 194, 232, 0.06) 7.41%, rgba(114, 192, 230, 0.105) 89.26%), #101216'}
  },
  {
    title: 'avax',
    currency: 'AVAXIcon',
    chain: 'avax',
    address:'0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
    color:
        {
            light:'linear-gradient(148.3deg, rgba(255, 203, 203, 0.13) 7.41%, rgba(248, 137, 130, 0.35) 89.26%), #FFFFFF',
            dark:'linear-gradient(148.3deg, rgba(255, 203, 203, 0.065) 7.41%, rgba(248, 137, 130, 0.175) 89.26%), #101216'}
  },


];

export const helps = [
  {
    label: "Getting Started",
    href: "https://hurricaneswap.gitbook.io/hurricaneswap/",
  },
  {
    label: "Tutorial for HurricaneSwap Testnet",
    href: "https://hurricaneswap.gitbook.io/hurricaneswap/testnet-tutorial",
  },
  {
    label: "Token",
    href: "https://hurricaneswap.gitbook.io/hurricaneswap/faq/7.-tokennomics",
  },
  {
    label: "Whitepaper",
    href: "https://test.hurricaneswap.com/whitepaper/",
  },

];

export const messages = [
  {
    label: "HurricaneSwap Public Sale Announcement & Tutorial",
    href: "https://hurricaneswap.medium.com/hurricaneswap-public-sale-announcement-tutorial-daec21b5d1d1",
  },

];

export default CoinToShow;

