/* eslint-disable import/prefer-default-export */

import {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';

/**
 * Example usage:
 *
 * ```
 * function MyComponent() {
 *   const { isLoading, result, load } = useAsync(() => fetch(...).then(...));
 *
 *   // Trigger load on mount
 *   useEffect(() => {
 *     load();
 *   }, [load]);
 *
 *   return (
 *     <div>{isLoading ? 'Loading...' : `The result: ${result}`}</div>
 *   );
 * }
 * ```
 */

export function useAsyncByAwait(getPromise) {
  const [data, setData] = useState({
    isLoading: false,
    result: undefined,
    error: undefined,
  });

  const latestGetPromise = useRef(getPromise);

  useEffect(() => {
    latestGetPromise.current = getPromise;
  }, [getPromise]);

  const load = useCallback(async (param ?:any) => {
    setData({
      isLoading: true,
      result: undefined,
      error: undefined,
    });
    try {
      const result = await latestGetPromise.current(param);
      setData({
        isLoading: false,
        result,
        error: undefined,
      });
    } catch (error) {
      setData({
        isLoading: false,
        result: undefined,
        error,
      });
      console.error(error)
    }
  }, []);
  return { ...data, load };
}
