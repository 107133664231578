import React, { RefObject, useEffect, useMemo, useState } from 'react'
import { CheckmarkCircleIcon, ErrorIcon, Flex, LinkExternal, Text, Button, CloseIcon } from '@hurricaneswap/uikit'
import { useActiveWeb3React } from 'hooks'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import styled, {css} from 'styled-components'
import AutoSizer from 'react-virtualized-auto-sizer'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/reducer'
import Loader from 'components/Loader'
import TranslatedText from '../TranslatedText'
import Column from '../Column'
import { AppState } from '../../state'
import { useStationActionHandlers } from '../../state/station/hooks'
import { setupNetwork } from '../../utils'
import { useSwitchChain } from '../../hooks/Station'
import Modal from '../Modal'
import { RowBetween } from '../Row'
import { PaddedColumn, SearchInput, Separator } from '../SearchModal/styleds'
import CurrencyLogo from '../CurrencyLogo'
import useStationDefaultInput from '../../hooks/useStationDefaultInput'

const Wrapper = styled.div`
  flex: 1;
  padding: 24px;
  overflow-y: scroll;
`

const ButtonContainer = styled.div<{ selected: boolean, disabled: boolean }>`
  height: 72px;
  background: rgba(76, 35, 118, 0.04);
  border-radius: 8px;
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
   ${ ({ selected, theme }) => (selected) && css`border: 1px solid ${theme.colors.borderColor};` }
   ${ ({ disabled, theme }) => (disabled) && css`cursor: no-drop;` }
`

const StyledBnbLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

// TODO: Fix UI Kit typings
const newTransactionsFirst = (a: TransactionDetails, b: TransactionDetails) => b.addedTime - a.addedTime

const getRowStatus = (sortedRecentTransaction: TransactionDetails) => {
  const { hash, receipt } = sortedRecentTransaction

  if (!hash) {
    return { icon: <Loader/>, color: 'text' }
  }

  if (hash && receipt?.status === 1) {
    return { icon: <CheckmarkCircleIcon color="success"/>, color: 'success' }
  }

  return { icon: <ErrorIcon color="failure"/>, color: 'failure' }
}

const ChooseChain = ({ isOpen, portalId, onDismiss }) => {
  const history = useHistory()
  const { account, chainId } = useActiveWeb3React ()
  const allTransactions = useAllTransactions ()
  const [id, setId]: any = useState()

  const checkChainId = async ()=>{
    const res = await setupNetwork()
    if(!res){
      history.push('/pool')
    }
  }

  const {allowStation, stationChainId} = useSelector<AppState, AppState['station']>((state) => state.station)
  const { onUpdateAllowStation, onUpdateStationChainId } = useStationActionHandlers()

  // Logic taken from Web3Status/index.tsx line 175
  const sortedRecentTransactions = useMemo (() => {
    const txs = Object.values (allTransactions)
    return txs.filter (isTransactionRecent).sort (newTransactionsFirst)
  }, [allTransactions])

  const switchChain = useSwitchChain()
  const defaultInput = useStationDefaultInput()

  const handleClick = async (_id) => {
    if(chainId === _id){
      onUpdateStationChainId (_id)
      setId(_id)
      return
    }
    const res = await switchChain (_id)
    if(res){
      onUpdateStationChainId (_id)
      setId(_id)
    }
  }


  useEffect(()=>{
    if(chainId === id){
      onDismiss ()
      window.localStorage.setItem ('STATION_CHAIN_ID', String (id))
      setTimeout(()=>{
        history.push (`/stationadd/${ id }/${defaultInput}`)
      } ,200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, id])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Column style={{ width: '100%', flex: '1 1' }}>
        <PaddedColumn gap="14px">
          <RowBetween>
            <Text>
              <TranslatedText translationId={82}>Select a network</TranslatedText>
            </Text>
            <CloseIcon style={{cursor: 'pointer'}} onClick={onDismiss} />
          </RowBetween>
        </PaddedColumn>

        <Wrapper>
            { !account && (
              <Flex justifyContent="center" flexDirection="column" alignItems="center">
                <Text mb="8px" bold>
                  Please connect your wallet to select a chain
                </Text>
              </Flex>
            ) }
            { account && chainId && !allowStation && (
              <Flex justifyContent="center" flexDirection="column" alignItems="center">
                <Text mb="8px" bold>
                  Station Closed
                </Text>
              </Flex>
            ) }
            {
              account && chainId && allowStation && (
                <Column style={{ width: '100%', flex: '1 1', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                  {
                    (!account)
                      ? (
                        <Button onClick={checkChainId}>
                          Connect Network
                        </Button>
                      ) : (
                        <>
                          <ButtonContainer
                            selected={chainId === 256}
                            key='256'
                            disabled={false}
                            onClick={()=>{handleClick(256)}}
                          >
                            <StyledBnbLogo src="/images/coins/HECO.svg" size='24px'/>
                            <Text ml="8px">Heco Test</Text>
                          </ButtonContainer>
                           <ButtonContainer
                             selected={chainId === 56}
                             key='56'
                             disabled
                           >
                             <StyledBnbLogo src="/images/coins/BSC.svg" size='24px'/>
                             <Text ml="8px">Binance Smart Chain (coming soon)</Text>
                           </ButtonContainer>
                          <ButtonContainer
                            selected={false}
                            disabled
                            key='128'
                          >
                            <StyledBnbLogo src="/images/coins/ETH.svg" size='24px' />
                            <Text ml="8px">Ethereum (coming soon)</Text>
                          </ButtonContainer>
                           <ButtonContainer
                             selected={false}
                             disabled
                             key='1'
                           >
                             <StyledBnbLogo src="/images/coins/HECO.svg" size='24px'/>
                             <Text ml="8px">HECO (coming soon)</Text>
                           </ButtonContainer>
                        </>
                      )
                  }
                </Column>
              )
            }
        </Wrapper>

      </Column>

    </Modal>
  )
}

export default ChooseChain
