import { CurrencyAmount, ETHER, JSBI, Token, Trade, WETH } from '@glhf-libs/sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ArrowDown } from 'react-feather'
import { Button, CardBody, Text } from '@hurricaneswap/uikit'
import styled, { ThemeContext } from 'styled-components'
import AddressInputPanel from 'components/AddressInputPanel'
import Card, { GreyCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import ConfirmSwapModal from 'components/swap/ConfirmSwapModal'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { AutoRow, RowBetween } from 'components/Row'
import AdvancedSwapDetailsDropdown from 'components/swap/AdvancedSwapDetailsDropdown'
import confirmPriceImpactWithoutFee from 'components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, IconDecoration, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import TradePrice from 'components/swap/TradePrice'
import TokenWarningModal from 'components/TokenWarningModal'
import SyrupWarningModal from 'components/SyrupWarningModal'
import ProgressSteps from 'components/ProgressSteps'
import TranslatedText from 'components/TranslatedText'

import { INITIAL_ALLOWED_SLIPPAGE } from 'constants/index'
import { useActiveWeb3React } from 'hooks'
import { useCurrency } from 'hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from 'hooks/useApproveCallback'
import { useSwapCallback } from 'hooks/useSwapCallback'
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback'
import { Field } from 'state/swap/actions'
import { useDefaultsFromURLSearch, useDerivedSwapInfo, useSwapActionHandlers, useSwapState } from 'state/swap/hooks'
import { useExpertModeManager, useUserDeadline, useUserSlippageTolerance } from 'state/user/hooks'
import { LinkStyledButton, TYPE } from 'components/Shared'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from 'utils/prices'
import Loader from 'components/Loader'
import { TranslateString } from 'utils/translateTextHelpers'
import PageHeader from 'components/PageHeader'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AppBody from '../AppBody'
import { useUpdateInputCurrency, useUpdateOutputCurrency } from '../../state/trading/hooks'
import '../../css/modal.css'
import useTheme from '../../hooks/useTheme'
import useOnlyOnAvax from '../../hooks/useOnlyOnAvax'
import { useSwitchChain } from '../../hooks/Station'
import useCrossChainTokenSwapStatus from '../../hooks/useCrossChainTokenSwapStatus'
import { AppState } from '../../state'
import { Dots } from '../../components/swap/styleds'


const { main: Main } = TYPE


const Fixed = styled.section`
  position: fixed;
  top: var(--modalTop);
  right: var(--modalRight);
`

const ZIndex = styled.section`
  position: relative;
  z-index: 0;
`

const SwapIcon = () => {
  const { isDark, toggleTheme, theme } = useTheme ()

  return (<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill={ isDark ? 'rgba(255,255,255,0.16)' : '#F1EEE6' }/>
    <path d="M18 16L15 13L12 16" stroke={ isDark ? '#FFF' : 'black' } strokeWidth="1.5" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path d="M15 27V13" stroke={ isDark ? '#FFF' : 'black' } strokeWidth="1.5" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path d="M22 24L25 27L28 24" stroke={ isDark ? '#FFF' : 'black' } strokeWidth="1.5" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path d="M25 13V27" stroke={ isDark ? '#FFF' : 'black' } strokeWidth="1.5" strokeLinecap="round"
          strokeLinejoin="round"/>
  </svg>)
}

export default function Swap ({ history }) {
  const loadedUrlParams = useDefaultsFromURLSearch ()
  const { chainId } = useActiveWeb3React ()
  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency (loadedUrlParams?.inputCurrencyId),
    useCurrency (loadedUrlParams?.outputCurrencyId)
  ]

  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean> (false)
  const [isSyrup, setIsSyrup] = useState<boolean> (false)
  const [syrupTransactionType, setSyrupTransactionType] = useState<string> ('')
  const urlLoadedTokens: Token[] = useMemo (
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter ((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback (() => {
    setDismissTokenWarning (true)
  }, [])

  const handleConfirmSyrupWarning = useCallback (() => {
    setIsSyrup (false)
    setSyrupTransactionType ('')
  }, [])

  const { account } = useActiveWeb3React ()
  const theme = useContext (ThemeContext)

  const [isExpertMode] = useExpertModeManager ()

  // get custom setting values for user
  const [deadline] = useUserDeadline ()
  const [allowedSlippage] = useUserSlippageTolerance ()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState ()
  const { v2Trade, currencyBalances, parsedAmount, currencies, inputError: swapInputError } = useDerivedSwapInfo ()
  // console.log('useDerivedSwapInfo', v2Trade, currencyBalances, parsedAmount, currencies, swapInputError)
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback (
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const trade = v2Trade

  const parsedAmounts = showWrap
    ? {
      [Field.INPUT]: parsedAmount,
      [Field.OUTPUT]: parsedAmount
    }
    : {
      [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
      [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
    }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers ()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback (
    (value: string) => {
      onUserInput (Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback (
    (value: string) => {
      onUserInput (Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }> ({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact () ?? ''
        : parsedAmounts[dependentField]?.toSignificant (6) ?? ''
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean (
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan (JSBI.BigInt (0))
  )

  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade (trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean> (false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect (() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted (true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend (currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean (maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo (maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback (
    trade,
    allowedSlippage,
    deadline,
    recipient
  )

  const { priceImpactWithoutFee } = computeTradePriceBreakdown (trade)

  const handleSwap = useCallback (() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee (priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState ((prevState) => ({
      ...prevState,
      attemptingTxn: true,
      swapErrorMessage: undefined,
      txHash: undefined
    }))
    swapCallback ()
      .then ((hash) => {
        setSwapState ((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: undefined,
          txHash: hash
        }))
      })
      .catch ((error) => {
        setSwapState ((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: error.message,
          txHash: undefined
        }))
      })
  }, [priceImpactWithoutFee, swapCallback, setSwapState])

  // errors
  const [showInverted, setShowInverted] = useState<boolean> (false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity (priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback (() => {
    setSwapState ((prevState) => ({ ...prevState, showConfirm: false }))

    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput (Field.INPUT, '')
    }
  }, [onUserInput, txHash, setSwapState])

  const handleAcceptChanges = useCallback (() => {
    setSwapState ((prevState) => ({ ...prevState, tradeToConfirm: trade }))
  }, [trade])

  // This will check to see if the user has selected Syrup to either buy or sell.
  // If so, they will be alerted with a warning message.
  const checkForSyrup = useCallback (
    (selected: string, purchaseType: string) => {
      if (selected === 'syrup') {
        setIsSyrup (true)
        setSyrupTransactionType (purchaseType)
      }
    },
    [setIsSyrup, setSyrupTransactionType]
  )


  const handleMaxInput = useCallback (() => {
    if (maxAmountInput) {
      onUserInput (Field.INPUT, maxAmountInput.toExact ())
    }
  }, [maxAmountInput, onUserInput])

  const switchTokens = () => {
    history.push ('/swap')
    // switchUrlParams()
    setApprovalSubmitted (false) // reset 2 step UI for approvals
    onSwitchTokens ()
  }

  const handleInputSelect = useCallback (
    (inputCurrency) => {
      history.push ('/swap')
      setApprovalSubmitted (false) // reset 2 step UI for approvals
      onCurrencySelection (Field.INPUT, inputCurrency)
      if (inputCurrency.symbol.toLowerCase () === 'syrup') {
        checkForSyrup (inputCurrency.symbol.toLowerCase (), 'Selling')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCurrencySelection, setApprovalSubmitted, checkForSyrup]
  )

  const handleOutputSelect = useCallback (
    (outputCurrency) => {
      history.push ('/swap')
      onCurrencySelection (Field.OUTPUT, outputCurrency)
      if (outputCurrency.symbol.toLowerCase () === 'syrup') {
        checkForSyrup (outputCurrency.symbol.toLowerCase (), 'Buying')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCurrencySelection, checkForSyrup]
  )

  const inputLoad = useUpdateInputCurrency ()
  const outputLoad = useUpdateOutputCurrency ()

  useEffect (() => {
    inputLoad (currencies[Field.INPUT])
    outputLoad (currencies[Field.OUTPUT])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencies[Field.INPUT], currencies[Field.OUTPUT]])

  const onlyOnAvax = useOnlyOnAvax ()
  const login = useSwitchChain ()

  const routerToStation = () => {
    history.push ('/stationpool')
  }

  const { allowStation } = useSelector<AppState, AppState['station']> ((state) => state.station)
  const { isLoading, load, result, error } = useCrossChainTokenSwapStatus ()
  const allowSwap = useMemo (() => {
    return !isLoading && !error && result
  }, [isLoading, result, error])

  useEffect (() => {
    if (currencies.INPUT && currencies.OUTPUT) {
      // @ts-ignore
      const token0Address = currencies.INPUT === ETHER ? WETH[chainId].address : currencies.INPUT.address
      // @ts-ignore
      const token1Address = currencies.OUTPUT === ETHER ? WETH[chainId].address : currencies.OUTPUT.address
      load ({ token0Address, token1Address })
    }
  }, [chainId, currencies.INPUT, currencies.OUTPUT, load])


  return (
    <>
      {
        false && (
          <TokenWarningModal
            isOpen={ urlLoadedTokens.length > 0 && !dismissTokenWarning }
            tokens={ urlLoadedTokens }
            onConfirm={ handleConfirmTokenWarning }
          />
        )
      }
      <SyrupWarningModal
        isOpen={ isSyrup }
        transactionType={ syrupTransactionType }
        onConfirm={ handleConfirmSyrupWarning }
      />

      <AppBody>
        <Wrapper id="swap-page">
          <ConfirmSwapModal
            isOpen={ showConfirm }
            trade={ trade }
            originalTrade={ tradeToConfirm }
            onAcceptChanges={ handleAcceptChanges }
            attemptingTxn={ attemptingTxn }
            txHash={ txHash }
            recipient={ recipient }
            allowedSlippage={ allowedSlippage }
            onConfirm={ handleSwap }
            swapErrorMessage={ swapErrorMessage }
            onDismiss={ handleConfirmDismiss }
          />

          <PageHeader title="EXCHANGE"/>
          <CardBody p='24px 24px 48px'>
            <AutoColumn gap="md">

              <CurrencyInputPanel
                portalId="swap-page"
                dir="From"
                label={
                  independentField === Field.OUTPUT && !showWrap && trade
                    ? 'From (estimated)'
                    : TranslateString (76, 'Swap from')
                }
                value={ formattedAmounts[Field.INPUT] }
                showMaxButton={ !atMaxAmountInput }
                currency={ currencies[Field.INPUT] }
                onUserInput={ handleTypeInput }
                onMax={ handleMaxInput }
                onCurrencySelect={ handleInputSelect }
                otherCurrency={ currencies[Field.OUTPUT] }
                id="swap-currency-input"
              />

              <AutoColumn justify="space-between">
                <AutoRow justify={ isExpertMode ? 'space-between' : 'flex-end' }>
                  {/* eslint-disable-next-line */ }
                  <IconDecoration/>

                  <ArrowWrapper clickable onClick={ switchTokens }>
                    <SwapIcon/>
                  </ArrowWrapper>
                  { recipient === null && !showWrap && isExpertMode ? (
                    <LinkStyledButton id="add-recipient-button" onClick={ () => onChangeRecipient ('') }>
                      + Add a send (optional)
                    </LinkStyledButton>
                  ) : null }
                </AutoRow>
              </AutoColumn>

              <CurrencyInputPanel
                portalId="swap-page"
                value={ formattedAmounts[Field.OUTPUT] }
                onUserInput={ handleTypeOutput }
                dir="To"
                label={
                  independentField === Field.INPUT && !showWrap && trade ? '' : TranslateString (80, '')
                }
                showMaxButton={ false }
                currency={ currencies[Field.OUTPUT] }
                onCurrencySelect={ handleOutputSelect }
                otherCurrency={ currencies[Field.INPUT] }
                id="swap-currency-output"
              />

              { recipient !== null && !showWrap ? (
                <>
                  <AutoRow justify="space-between">
                    <ArrowWrapper clickable={ false }>
                      <ArrowDown size="16" color={ theme.colors.textSubtle }/>
                    </ArrowWrapper>
                    <LinkStyledButton id="remove-recipient-button" onClick={ () => onChangeRecipient (null) }>
                      - Remove send
                    </LinkStyledButton>
                  </AutoRow>
                  <AddressInputPanel id="recipient" value={ recipient } onChange={ onChangeRecipient }/>
                </>
              ) : null }

              { showWrap ? null : (
                <Card padding=".25rem 0 0 0" borderRadius="20px">
                  <AutoColumn gap="4px">
                    { Boolean (trade) && (
                      <RowBetween align="center">
                        <Text fontSize="14px">Price</Text>
                        <TradePrice
                          price={ trade?.executionPrice }
                          showInverted={ showInverted }
                          setShowInverted={ setShowInverted }
                        />
                      </RowBetween>
                    ) }
                    { allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                      <RowBetween align="center">
                        <Text fontSize="14px">Slippage Tolerance</Text>
                        <Text fontSize="14px">{ allowedSlippage / 100 }%</Text>
                      </RowBetween>
                    ) }
                  </AutoColumn>
                </Card>
              ) }
            </AutoColumn>
            <BottomGrouping>
              { !account ? (
                <ConnectWalletButton/>
              ) : showWrap ? (
                <Button disabled={ Boolean (wrapInputError) } onClick={ onWrap } style={ { width: '100%' } }>
                  { wrapInputError ??
                    (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null) }
                </Button>
              ) : noRoute && userHasSpecifiedInputOutput ? (
                noRoute
                  ? (
                    <GreyCard style={ { textAlign: 'center' } }>
                      <Main mb="4px">Loading<Dots/></Main>
                    </GreyCard>
                  )
                  : (
                    <GreyCard style={ { textAlign: 'center' } }>
                      <Main mb="4px">Insufficient liquidity for this trade.</Main>
                    </GreyCard>
                  )

              ) : showApproveFlow ? (
                <RowBetween>
                  <Button
                    onClick={ approveCallback }
                    disabled={ approval !== ApprovalState.NOT_APPROVED || approvalSubmitted }
                    style={ { width: '100%' } }
                    variant={ approval === ApprovalState.APPROVED ? 'success' : 'primary' }

                  >
                    { approval === ApprovalState.PENDING ? (
                      <AutoRow gap="6px" justify="center">
                        Approving <Loader stroke="white"/>
                      </AutoRow>
                    ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                      'Approved'
                    ) : (
                      `Approve ${ currencies[Field.INPUT]?.symbol }`
                    ) }
                  </Button>
                  <Button
                    onClick={ () => {
                      if (isExpertMode) {
                        handleSwap ()
                      } else {
                        setSwapState ({
                          tradeToConfirm: trade,
                          attemptingTxn: false,
                          swapErrorMessage: undefined,
                          showConfirm: true,
                          txHash: undefined
                        })
                      }
                    } }
                    style={ { width: '100%' } }
                    id="swap-button"
                    disabled={
                      !isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
                    }
                    variant={ isValid && priceImpactSeverity > 2 ? 'danger' : 'primary' }
                  >
                    { priceImpactSeverity > 3 && !isExpertMode
                      ? `Price Impact High`
                      : `Swap${ priceImpactSeverity > 2 ? ' Anyway' : '' }` }
                  </Button>
                </RowBetween>
              ) : (
                onlyOnAvax ? (
                  <Button id="switch-network-button" onClick={ login } style={ { width: '100%' } }>
                    <TranslatedText translationId={ 100 }>Switch Network</TranslatedText>
                  </Button>
                ) : allowSwap
                  ? (
                    <Button
                      onClick={ () => {
                        if (isExpertMode) {
                          handleSwap ()
                        } else {
                          setSwapState ({
                            tradeToConfirm: trade,
                            attemptingTxn: false,
                            swapErrorMessage: undefined,
                            showConfirm: true,
                            txHash: undefined
                          })
                        }
                      } }
                      id="swap-button"
                      disabled={ !isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError }
                      variant={ isValid && priceImpactSeverity > 2 && !swapCallbackError ? 'danger' : 'primary' }
                      width='100%'
                    >
                      <TranslatedText translationId={ 100 }>
                        { swapInputError ||
                        (priceImpactSeverity > 3 && !isExpertMode
                          ? `Price Impact Too High`
                          : `Swap${ priceImpactSeverity > 2 ? ' Anyway' : '' }`) }
                      </TranslatedText>
                    </Button>
                  )
                  : (
                    allowStation
                      ? (
                        <Button id="switch-network-button" onClick={ routerToStation } style={ { width: '100%' } }>
                          <TranslatedText translationId={ 100 }>Station Is Now Opened</TranslatedText>
                        </Button>
                      )
                      : (
                        <Button id="switch-network-button" disabled style={ { width: '100%' } }>
                          <TranslatedText translationId={ 100 }>Invalid Token</TranslatedText>
                        </Button>
                      )

                  )
              ) }
              { showApproveFlow && <ProgressSteps steps={ [approval === ApprovalState.APPROVED] }/> }
              { isExpertMode && swapErrorMessage ? <SwapCallbackError error={ swapErrorMessage }/> : null }
            </BottomGrouping>
          </CardBody>
        </Wrapper>
      </AppBody>
      {
        !showConfirm ? <AdvancedSwapDetailsDropdown trade={ trade }/> : null
      }
    </>
  )
}
